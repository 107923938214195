<template>
  <div class="content-wrapper">
    <div class="content">
      <!--      <the-mobile-scroll v-model:current-scroll-value="currentScrollValue" />-->
      <contact-icons class="mobile" v-if="activePageIndex === 0" />

      <transition name="fade">
        <div v-if="[1, 2, 3, 4].includes(activePageIndex)" class="content-meta">
          <a class="contact-phone" href="tel:+79892224422">+7 989 222 44 22</a>
          <contact-icons class="mobile" />
        </div>
      </transition>
      <div class="content__page">
        <page-slider-mobile />
      </div>
    </div>
  </div>
</template>

<script>
  import TheMobileScroll from '@/components/Mobile/TheMobileScroll'
  import PageContent from '@/components/Mobile/PageContentMobile'
  import MainPage from '@/components/Mobile/Pages/MainPageMobile'
  import PageSliderMobile from '@/components/Mobile/PageSliderMobile'
  import ContactIcons from '@/components/Desktop/ContactIcons'
  import { mapGetters } from 'vuex'
  export default {
    name: 'TheMobileContent',
    // eslint-disable-next-line vue/no-unused-components
    components: {
      ContactIcons,
      PageSliderMobile,
      // eslint-disable-next-line vue/no-unused-components
      MainPage,
      // eslint-disable-next-line vue/no-unused-components
      TheMobileScroll,
      // eslint-disable-next-line vue/no-unused-components
      PageContent,
    },
    data() {
      return {
        currentScrollValue: 0,
      }
    },
    computed: {
      ...mapGetters(['activePageIndex', 'pageList']),
      transformTitle() {
        return (50 - this.currentScrollValue) / 4 + 'px'
      },
    },
  }
</script>

<style scoped lang="scss">
  .content {
    user-select: none;
    position: sticky;
    display: flex;
    height: 100vh;
    width: 100%;
    top: 0;
    color: white;

    &-meta {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1000000;
      padding: 5vw;
      width: 100%;
      .mobile {
        padding: 0;
        position: relative;
        max-width: 22vw;
      }

      .contact-phone {
        font-size: 3.4vw;
        text-shadow: -1px -1px 4px #414141, -1px 0 4px #414141, 0 -1px 4px #414141,
          1px 0 4px #414141, 0 1px 4px #414141;
        font-weight: bold;
      }
    }

    &__page {
      position: relative;
      height: 100vh;
      width: 100%;
    }
    &-wrapper {
      height: 100vh;
      position: absolute;
      width: 100%;
      top: 0;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
