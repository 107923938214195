<template>
  <!--  <the-header />-->
  <pre-loader :class="{ active: isLoading }" />
  <the-content :class="{ 'no-visible': isMobile }" />
  <the-mobile-content :class="{ 'no-visible': !isMobile }" />

  <teleport to="#modal">
    <div class="catch-modal" v-if="notification.isShow">
      <div class="d-flex flex-column align-center">
        <span>
          {{ notification.title }}
        </span>
        <span v-if="notification.subtitle">
          {{ notification.subtitle }}
        </span>

        <div
          @click="$store.commit('notification/setNotification', { isShow: false })"
          class="catch-modal__action button"
        >
          Ок
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
  import TheHeader from '@/components/Desktop/TheHeader'
  import TheContent from '@/components/Desktop/TheContent'
  import { useDebounceFn, useWindowSize } from '@vueuse/core'
  import TheMobileContent from '@/components/Mobile/TheMobileContent'
  import appConfig from '@/appConfig'
  import { useWindowScroll } from '@vueuse/core/index'
  import PreLoader from '@/components/PreLoader'
  import { mapGetters } from 'vuex'

  const { width } = useWindowSize()
  const { y } = useWindowScroll()
  export default {
    name: 'App',
    components: {
      PreLoader,
      TheMobileContent,
      TheContent,
      // eslint-disable-next-line vue/no-unused-components
      TheHeader,
    },
    data() {
      return {
        scrollY: y,
        isMobile: null,
        windowWidth: width,
        nextPageInterval: null,
        isResizing: false,
        fn: null,
        isLoading: true,
        fnScroll: null,
      }
    },
    computed: {
      ...mapGetters('notification', ['notification']),
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
      pageHeight() {
        return this.$store.getters.pageHeight
      },
    },
    mounted() {
      this.$store.commit('setPageHeight', window.innerHeight)
      window.addEventListener('resize', this.updatePageHeightAndRecalculationScroll)
      window.addEventListener('load', () => {
        this.isLoading = false
        this.$metrika.hit('https://avacore.ru')
      })

      // function debounce(cb, delay = 250) {
      //   let timeout
      //
      //   return (...args) => {
      //     clearTimeout(timeout)
      //     timeout = setTimeout(() => {
      //       cb(...args)
      //     }, delay)
      //   }
      // }

      function throttle(cb, delay = 250) {
        let shouldWait = false
        let lastCallTimeOut = null

        return (...args) => {
          if (shouldWait) {
            if (lastCallTimeOut) {
              clearTimeout(lastCallTimeOut)
              lastCallTimeOut = setTimeout(() => {
                shouldWait = false
                lastCallTimeOut = null
              }, delay)
            }
            return
          }

          cb(...args)
          shouldWait = true
          lastCallTimeOut = setTimeout(() => {
            shouldWait = false
            lastCallTimeOut = null
          }, delay)
        }
      }

      this.fnScroll = throttle((e) => {
        if (e.wheelDeltaY > 0 && this.activePageIndex > 0) {
          this.$store.commit('setActivePageIndex', this.activePageIndex - 1)
        } else if (e.wheelDeltaY < 0 && this.activePageIndex < 6) {
          this.$store.commit('setActivePageIndex', this.activePageIndex + 1)
        }
        window.document.scrollingElement.scrollTop = 0
      }, 80)

      window.addEventListener('wheel', this.fnScroll)

      this.fn = useDebounceFn(() => {
        this.$store.commit('setPageHeight', window.innerHeight)
        this.isResizing = false
        // window.document.scrollingElement.scrollTop =
        //   (this.pageHeight + 1) * this.activePageIndex
      }, 100)
    },
    unmounted() {
      window.removeEventListener('resize', this.updatePageHeightAndRecalculationScroll)
      window.removeEventListener('wheel', this.fnScroll)
    },
    methods: {
      updatePageHeightAndRecalculationScroll() {
        this.isResizing = true
        this.fn()
      },
    },
    watch: {
      windowWidth: {
        immediate: true,
        handler() {
          this.isMobile = this.windowWidth <= 766
        },
      },
      // eslint-disable-next-line no-unused-vars
      scrollY(newValue, oldValue) {
        // if (newValue !== oldValue && !this.isResizing) {
        //   this.$store.commit(
        //     'setActivePageIndex',
        //     (newValue - (newValue % this.pageHeight)) / this.pageHeight
        //   )
        //   if (newValue + 10 >= this.pageHeight * 6) {
        //     this.$store.commit('setActivePageIndex', 6)
        //   }
        // }
      },

      activePageIndex: {
        immediate: true,
        handler() {
          clearTimeout(this.nextPageInterval)
          if (this.activePageIndex < appConfig.lastPageIndex) {
            this.nextPageInterval = setTimeout(() => {
              window.document.scrollingElement.scrollTop =
                (this.pageHeight + 1) * (this.activePageIndex + 1)
            }, 99999999)
          }
        },
      },
    },
  }
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  .catch-modal {
    $black: #212121;
    position: fixed;
    top: 0;
    left: 0;
    padding: 6vw;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#000, 0.4);
    color: $black;
    z-index: 100;
    font-family: 'Roboto', sans-serif;
    div.d-flex.flex-column.align-center {
      padding: 2.5vw 4vw 2.5vw 4vw;
      border-radius: 8px;
      background: rgba(#fff, 0.85);
      box-shadow: 1px 1px 8px 1px #41414188;

      & > span {
        font-weight: 600;
        font-size: 1.4em;

        & + span {
          margin-top: 0.5vw;
          font-weight: 400;
          font-size: 1.3em;
          text-align: justify;
        }
      }

      .button {
        margin-top: 1vw;
        text-align: center;
        border: 2px solid rgba($black, 0.2);
        background: $black;
        color: white;
        padding: 0.83vw;
        font-size: 0.83vw;
        width: 50%;
        transition: 0.3s;
        font-family: 'Roboto', sans-serif;

        &:hover {
          cursor: pointer;
          border: 2px solid rgba($black, 1);
        }
      }
    }
  }

  @media (max-width: 765px) {
    .catch-modal {
      div.d-flex.flex-column.align-center {
        padding: 2.5vw 4vw 2.5vw 4vw;

        & > span {
          font-size: 1.3em;

          & + span {
            margin-top: 1.5vw;
            margin-bottom: 1.5vw;
            font-size: 1em;
          }
        }

        .button {
          margin-top: 1vw;
          padding: 1.83vw;
          font-size: 2.83vw;
        }
      }
    }
  }

  #app {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .no-visible {
    display: none;
  }
  body {
    jdiv {
      z-index: 1;
    }
  }

  #timera54c1352283122fb0b3407d6784b7715 {
    --el-size: 6vw;
    min-width: calc(var(--el-size) * 3.5) !important;
    height: var(--el-size) !important;

    .timer-separator {
      padding: 0 calc(var(--el-size) / 4) !important;
      height: var(--el-size) !important;
    }

    .timer-element {
      height: var(--el-size) !important;
      width: var(--el-size) !important;
      display: flex !important;
      flex-direction: column;
      justify-content: center;

      #timer-number-a54c1352283122fb0b3407d6784b7715-days,
      #timer-number-a54c1352283122fb0b3407d6784b7715-hours,
      #timer-number-a54c1352283122fb0b3407d6784b7715-minutes,
      #timer-number-a54c1352283122fb0b3407d6784b7715-secundes {
        font-size: calc(var(--el-size) / 3) !important;
      }

      #timer-canvas-a54c1352283122fb0b3407d6784b7715-days,
      #timer-canvas-a54c1352283122fb0b3407d6784b7715-hours,
      #timer-canvas-a54c1352283122fb0b3407d6784b7715-minutes,
      #timer-canvas-a54c1352283122fb0b3407d6784b7715-secundes {
        height: var(--el-size) !important;
        width: var(--el-size) !important;
      }
      #timer-text-a54c1352283122fb0b3407d6784b7715-days,
      #timer-text-a54c1352283122fb0b3407d6784b7715-hours,
      #timer-text-a54c1352283122fb0b3407d6784b7715-minutes,
      #timer-text-a54c1352283122fb0b3407d6784b7715-secundes {
        font-size: calc(var(--el-size) / 6) !important;
      }
    }
  }

  @media (max-width: 766px) {
    #timer9711a614e002e2f7e4e73d00c512a5d3 {
      --el-size: 12.5vw;
    }
  }
</style>
