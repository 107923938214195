<template>
  <div class="file-input">
    <label
      @dragover.prevent="isOver = true"
      @dragleave="isOver = false"
      @drop.prevent="handleFileDrop"
      class="file-input__label"
      :class="{ isOver: isOver }"
      for="fileInput"
    >
      <span class="file-input__info">
        {{ modelValue?.name ? modelValue.name : 'Прикрепите файл' }}
      </span>
    </label>
    <input
      class="file-input__input"
      type="file"
      @change="handleFileInput($event.target.files)"
      id="fileInput"
    />
  </div>
</template>

<script>
  export default {
    name: 'FileInput',
    props: {
      color: {
        default: '#212121',
      },
      colorBrightless: {
        default: '#2121214A',
      },
      modelValue: [null, Object, File],
    },
    data() {
      return {
        isOver: false,
      }
    },
    methods: {
      handleFileInput(files) {
        if (files[0]) this.$emit('update:modelValue', files[0])
      },
      handleFileDrop(e) {
        //ПРИ D&D
        const file = e.dataTransfer.files[0]
        if (file) this.$emit('update:modelValue', file)
      },
    },
  }
</script>

<style scoped lang="scss">
  .isOver {
    .file-input__info {
      background: #f5f5f5;
    }
  }
  .file-input {
    width: 100%;
    &__info {
      display: inline-block;
      border: 2px dashed v-bind(colorBrightless);
      font-size: var(--font-size-lesser);
      white-space: nowrap;
      padding: var(--el-padding-vertical);
      transition: 0.3s;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__label {
      &:hover {
        .file-input__info {
          cursor: pointer;
          border: 2px dashed v-bind(color);
        }
      }
    }
    &__input {
      display: none;
    }
  }
</style>
