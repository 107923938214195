<template>
  <div class="page team">
    <h1 class="team__title">
      {{ page.mobile.top_title }}
    </h1>
    <!--    <div class="wrapper">-->
    <!--      <img class="icon" :src="content.js" alt="" />-->
    <!--      <img class="icon" :src="content.vue" alt="" />-->
    <!--      <img class="icon" :src="content.python" alt="" />-->
    <!--      <img class="icon" :src="content.swift" alt="" />-->
    <!--      <img class="icon" :src="content.java" alt="" />-->
    <!--      <img class="icon" :src="content.kotlin" alt="" />-->
    <!--    </div>-->
    <team-slider-mobile />
  </div>
</template>
<script setup>
  import TeamSliderMobile from '@/components/Mobile/TeamSliderMobile'

  import appContent from '@/appContent'

  const page = appContent.pageList.find((page) => page.name === 'Team')
</script>
<script>
  export default {
    name: 'TeamPageMobile',
  }
</script>

<style scoped lang="scss">
  .team {
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3vw;
      font-size: 7.5vw;
      height: 12vh;
    }
  }
  .page {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    z-index: 10000;
  }
</style>
