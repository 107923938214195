<template>
  <div class="form" ref="form" :class="{ checked: wasCheck }">
    <div class="form__wrapper">
      <slot></slot>
      <div v-if="isMobile" class="form__item">
        <div class="form__item_title">Стоимость услуги</div>
        <p>
          Цена на услугу разработки начинается
          <br />
          <b>от 1 млн. рублей.</b>
          Конечная стоимость обсуждается при заключении договора на оказание услуг.
        </p>
      </div>
      <div class="form__item">
        <div class="form__item_title">Выберите услугу</div>
        <div class="form__item_input type-list">
          <div
            v-for="(item, index) in projectTypeList"
            :key="index"
            class="type"
            :class="{
              active: formDataFromStore.projectType === index,
              ml: index % 2,
              mt: index > 1,
            }"
            @click="updateProjectType(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="form__item">
        <div class="form__item_title">Выберите бюджет проекта</div>
        <div class="form__item_input type-list">
          <div
            v-for="(item, index) in budgetList"
            :key="index"
            class="type"
            :class="{
              active: formDataFromStore.budget === index,
              ml: index % 2,
              mt: index > 1,
            }"
            @click="updateBudget(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="form__item">
        <div class="form__item_title">Описание проекта</div>
        <div class="form__item_input">
          <autoresize-textarea
            @scroll.stop
            @mousewheel.stop
            placeholder="Введите описание проекта"
            :model-value="formDataFromStore.description"
            @update:modelValue="updateDescription"
          />
        </div>
      </div>
      <div class="form__item">
        <file-input
          :model-value="formDataFromStore.file"
          @update:modelValue="updateFile"
        />
      </div>
      <div class="form__item">
        <div class="form__item_title">Контактные данные</div>
        <div class="form__item_input contacts">
          <div class="input-wrapper">
            <input
              type="text"
              class="input"
              :class="{ invalid: wasCheck && !formDataFromStore.name }"
              :value="formDataFromStore.name"
              @input="updateName($event.target.value)"
              placeholder="Как вас зовут?"
            />
            <span v-if="wasCheck && !formDataFromStore.name" class="error-message">
              Введите имя
            </span>
          </div>
          <div class="input-wrapper">
            <input
              type="text"
              class="input tel-input"
              v-mask="'+7-(###)-###-##-##'"
              :value="formDataFromStore.tel"
              placeholder="Телефон"
              @change="updatePhoneValue($event.target.value)"
            />
          </div>
          <div class="input-wrapper">
            <input
              type="text"
              class="input"
              :class="{ invalid: wasCheck && !isValidEmail }"
              :value="formDataFromStore.email"
              @input="updateEmail($event.target.value)"
              placeholder="Email"
            />
            <span v-if="wasCheck && !isValidEmail" class="error-message">
              Введите корректный адрес электронной почты
            </span>
          </div>
        </div>
      </div>
      <div class="form__item">
        <div class="politics" data-v-12c0c98c="">
          <div
            class="politics__checkbox-wrapper"
            :class="{ invalid: wasCheck && !formDataFromStore.isAgree }"
            @click="updateIsAgree"
          >
            <div
              class="politics__checkbox"
              :class="{ active: formDataFromStore.isAgree }"
            ></div>
          </div>
          <div class="politics__text" data-v-12c0c98c="">
            Я согласен на
            <a
              href="/политика персональных данных.pdf"
              data-v-12c0c98c=""
              target="_blank"
              style="text-decoration: underline; color: rgb(62, 62, 62)"
            >
              обработку персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="form__action">
      <div @click="submitHandler" class="button">Отправить заявку</div>
    </div>
    <request-loader v-if="isLoading" />
  </div>
</template>

<script>
  import AutoresizeTextarea from '@/components/AutoresizeTextarea'
  import FileInput from '@/components/FileInput'
  import axios from 'axios'
  import RequestLoader from '@/components/RequestLoader'
  export default {
    name: 'ContactForm',
    components: { RequestLoader, FileInput, AutoresizeTextarea },
    props: {
      isMobile: Boolean,
    },
    data() {
      return {
        budgetList: ['до 1 млн', '1-3 млн', '3-5 млн', 'Не знаю'],
        projectTypeList: ['Мобильное приложение', 'Web-платформа', 'Нейросеть', 'Боты'],
        wasCheck: false,
        isLoading: false,
      }
    },
    computed: {
      isValidEmail() {
        return /^.+@.+\..+$/.test(this.formDataFromStore.email)
      },
      formDataFromStore() {
        return this.$store.getters.formData
      },
    },
    mounted() {
      this.$refs.form.addEventListener('scroll', this.updateCanSwipe)
    },
    unmounted() {
      this.$refs.form.removeEventListener('scroll', this.updateCanSwipe)
    },
    methods: {
      updateCanSwipe(e) {
        this.$store.commit('setIsMobileFormCanSwipe', e.target.scrollTop === 0)
      },
      submitHandler() {
        this.wasCheck = true

        if (
          this.formDataFromStore.isAgree &&
          this.isValidEmail &&
          this.formDataFromStore.name
        ) {
          const formData = new FormData()

          formData.append('name', JSON.stringify(this.formDataFromStore.name))
          formData.append('phone', JSON.stringify('+' + this.formDataFromStore.tel))
          formData.append('email', JSON.stringify(this.formDataFromStore.email))
          formData.append('file', this.formDataFromStore.file)
          formData.append(
            'type',
            'AVACore: ' +
              JSON.stringify(this.projectTypeList[this.formDataFromStore.projectType])
          )
          formData.append(
            'budget',
            JSON.stringify(this.budgetList[this.formDataFromStore.budget])
          )
          formData.append(
            'description',
            JSON.stringify(this.formDataFromStore.description ?? null)
          )

          this.isLoading = true

          const promises = []

          promises.push(
            axios
              .post('https://axas.ru/portfolio/api/new_project.php', formData)
              .then(() => {
                this.$store.commit('clearFormData')
                this.wasCheck = false
              })
              .finally(() => (this.isLoading = false))
          )

          promises.push(
            axios.get(
              `https://axas.bitrix24.ru/rest/1/v43ow0q8h42amca5/crm.lead.add.json?FIELDS[TITLE]=AVACore:${encodeURIComponent(
                this.projectTypeList[this.formDataFromStore.projectType] +
                  '_' +
                  this.budgetList[this.formDataFromStore.budget]
              )}&FIELDS[NAME]=${encodeURIComponent(
                JSON.stringify(this.formDataFromStore.name)
              )}&FIELDS[EMAIL][0][VALUE]=${
                this.formDataFromStore.email
              }&FIELDS[PHONE][0][VALUE]=${
                '%2B' + this.formDataFromStore.tel
              }&FIELDS[OPPORTUNITY]=0&FIELDS[CURRENCY]=RUB&FIELDS[COMMENTS]=${encodeURIComponent(
                this.formDataFromStore.description
              )}`
            )
          )

          Promise.any(promises)
            .then(() => {
              this.$store.commit('notification/setNotification', {
                isShow: true,
                title: 'Заявка успешно отправлена!',
                subtitle: 'Мы свяжемся с вами в ближайшее время.',
              })  
            })
            .catch((err) => {
              this.$store.commit('notification/setNotification', {
                title: 'Не удалось отправить заявку',
                subtitle: 'Воспользуйтесь контактами или попробуйте позже.',
                isShow: true,
              })
              console.log(err)
            })
        }
      },

      updateEmail(newValue) {
        this.$store.commit('updateFormData', {
          email: newValue,
        })
      },
      updateName(newValue) {
        this.$store.commit('updateFormData', {
          name: newValue,
        })
      },
      updateFile(newValue) {
        this.$store.commit('updateFormData', {
          file: newValue,
        })
      },
      updateDescription(newValue) {
        this.$store.commit('updateFormData', {
          description: newValue,
        })
      },

      updateIsAgree() {
        this.$store.commit('updateFormData', {
          isAgree: !this.formDataFromStore.isAgree,
        })
      },
      updateProjectType(newValue) {
        this.$store.commit('updateFormData', {
          projectType: newValue,
        })
      },
      updateBudget(newValue) {
        this.$store.commit('updateFormData', {
          budget: newValue,
        })
      },
      updatePhoneValue(value) {
        let newNumber = ''
        for (let char of value) {
          if ('0123456789'.includes(char)) {
            newNumber += char
          }
        }
        this.$store.commit('updateFormData', {
          tel: newNumber,
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  $black: #212121;

  .form__wrapper {
    display: flex;
    flex-direction: column;
  }

  .error-message {
    color: red;
    font-size: 0.75vw;
    line-height: 1.4;
    padding: 0.4vw;
  }
  .form {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1.5vw 3vw;
    height: 100%;
    margin-bottom: 3vh;
    margin-top: 3vh;
    left: 6%;
    box-shadow: 1px 1px 8px rgba($black, 0.3);
    color: $black;
    border-radius: 1vw;
    background: #fff;
    max-width: 45vw;
    overflow-y: auto;
    --el-padding-horizontal: 1.4vw;
    --el-padding-vertical: 0.8vw;
    --el-padding: var(--el-padding-vertical) var(--el-padding-horizontal);
    --el-margin: 0.65vw;
    --font-size-larger: 1.15vw;
    --font-size-middle: 0.95vw;
    --font-size-lesser: 0.8vw;

    .ml {
      margin-left: var(--el-margin);
    }
    .mt {
      margin-top: var(--el-margin);
    }
    .contacts {
      .input {
        width: 100%;
      }
    }
    &__action {
      margin-top: calc(var(--el-margin) * 2);
      margin-bottom: var(--el-margin);
      display: flex;
      justify-content: center;

      .button {
        text-align: center;
        border: 2px solid rgba($black, 0.2);
        padding: var(--el-padding);
        font-size: var(--font-size-larger);
        transition: 0.3s;
        font-family: 'Roboto', sans-serif;

        &:hover {
          cursor: pointer;
          border: 2px solid rgba($black, 1);
        }
      }
    }
    &__item {
      &_title {
        font-size: var(--font-size-larger);
        margin-bottom: var(--el-margin);
        font-family: 'Roboto', sans-serif;
      }
      p {
        text-align: justify;
        font-size: 3.5vw;
        b {
          font-family: Roboto;
          font-style: italic;
          font-size: 3.8vw;
        }
      }
      &_input {
        display: flex;

        .input.invalid {
          animation: 0.3s 2 blink forwards;
        }

        .input-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          &:not(:first-child) {
            margin-left: var(--el-margin);
          }
        }

        &.type-list {
          flex-wrap: wrap;
        }

        & + & {
          margin-top: var(--el-margin);
        }
        .type {
          padding: var(--el-padding);
          border: 2px solid rgba($black, 0.2);
          font-size: var(--font-size-lesser);
          transition: 0.3s;
          flex: 1 0 48%;
          display: flex;
          justify-content: center;
          border-radius: 0.4vw;
          &.active {
            border: 2px solid rgba($black, 1);
          }

          &:hover {
            cursor: pointer;
          }
        }

        textarea {
          width: 100%;
          min-height: fit-content;
          overflow-y: hidden;
          font-size: var(--font-size-lesser);
          padding: var(--el-padding-vertical);
          border: 2px solid rgba($black, 0.2);
          vertical-align: center;
          &:focus {
            border: 2px solid $black;
          }
        }

        input {
          padding: var(--el-padding-vertical);
          font-size: var(--font-size-lesser);
          border: 2px solid rgba($black, 0.2);
          display: flex;

          &:focus {
            border: 2px solid $black;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: var(--el-margin);
      }

      .politics {
        margin-top: calc(var(--el-margin) / 2);
        display: flex;
        align-items: center;
        &__checkbox-wrapper {
          padding: calc(var(--el-padding-horizontal) / 2);
          background: rgba($black, 0.05);
          width: fit-content;
          border: 2px solid transparent;

          &.invalid {
            background: #fff;
            animation: 0.3s 2 blink forwards;
          }
        }
        &__checkbox {
          padding: calc(var(--el-padding-vertical) / 2);
          background: rgba($black, 0.15);

          &.active {
            background: #6f8dc2;
          }
        }
        &__text {
          font-size: var(--font-size-middle);
          margin-left: var(--el-margin);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .error-message {
      padding: 0.8vw;
      font-size: 2.1vw;
    }
    .form {
      max-width: 100vw;
      left: 0;
      margin: 0;
      border-radius: 0;
      $black: #212121;
      padding: 2.5vw 5vw;
      --el-padding-horizontal: 3vw;
      --el-padding-vertical: 2vw;
      --el-padding: var(--el-padding-vertical) var(--el-padding-horizontal);
      --el-margin: 2vw;
      --font-size-larger: 3vw;
      --font-size-middle: 2.2vw;
      --font-size-lesser: 2vw;

      .file-input {
        &__info {
          max-width: 100%;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .error-message {
      font-size: 3vw;
    }
    .form {
      max-width: 100vw;
      left: 0;
      margin: 0;
      border-radius: 0;
      $black: #212121;
      padding: 5vw;
      --el-padding-horizontal: 4.2vw;
      --el-padding-vertical: 3vw;
      --el-padding: var(--el-padding-vertical) var(--el-padding-horizontal);
      --el-margin: 4.5vw;
      --font-size-larger: 5vw;
      --font-size-middle: 4.3vw;
      --font-size-lesser: 4vw;
      .file-input {
        &__info {
          width: 100%;
          text-align: center;
        }
      }
      .contacts {
        flex-wrap: wrap;
        .input {
          margin-left: 0;
        }
      }
      .form__item_input {
        .input-wrapper {
          &:not(:first-child) {
            margin-left: 0;
            margin-top: var(--el-margin);
          }
        }
        &.type-list {
          flex-wrap: wrap;
          .type {
            flex: 1 0 100%;
            margin-left: 0 !important;

            &:not(:first-child) {
              margin-top: var(--el-margin);
            }
          }
        }
      }
    }
  }

  @keyframes blink {
    0% {
      border: 2px solid rgba(red, 0.6);
    }
    50% {
      border: 2px solid transparent;
    }
    100% {
      border: 2px solid rgba(red, 0.6);
    }
  }
</style>
