<template>
  <div class="page payments">
    <img class="moc" :src="content.moc1" alt="" />

    <img class="moc" :src="content.moc5" alt="" />
    <img class="moc" :src="content.moc2" alt="" />
    <img class="moc" :src="content.moc4" alt="" />
    <img class="moc" :src="content.moc3" alt="" />
  </div>
</template>

<script setup>
  import appContent from '@/appContent'

  const page = appContent.pageList.find((page) => page.name === 'Payments')
  const content = page.content
</script>

<script>
  export default {
    name: 'PaymentsMobile',
  }
</script>

<style scoped lang="scss">
  .active {
    .page.payments {
      .moc,
      .logo {
        transform: translateY(0);
      }
    }
  }

  .page {
    overflow: hidden;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9998;
    .moc {
      $size: 25vw;

      position: absolute;
      width: $size;
      object-fit: contain;
      border-radius: 3.3vw;
      transform: translateY(-70vh);

      &:nth-child(1) {
        left: 10%;
        top: 2%;
        transition: 0.7s;
      }
      &:nth-child(3) {
        left: calc(50% - $size / 2);
        top: 5%;
        transition: 0.7s;
      }
      &:nth-child(2) {
        right: 10%;
        top: 2%;
        transition: 0.7s;
      }
      &:nth-child(4) {
        right: 15%;
        top: calc(50% - $size / 2);
        transition: 1.5s;
      }
      &:nth-child(5) {
        left: 15%;
        top: calc(50% - $size / 2);
        transition: 1.5s;
      }
    }
  }

  @media (max-width: 550px) {
    .page {
      .moc {
        $size: 30vw;
        width: $size;
        &:nth-child(1) {
          left: 5%;
          top: 4%;
        }
        &:nth-child(3) {
          top: 8%;
          left: calc(50% - $size / 2);
        }
        &:nth-child(2) {
          right: 5%;
          top: 4%;
        }
        &:nth-child(4) {
          right: 8%;
          bottom: 20%;
        }
        &:nth-child(5) {
          left: 8%;
          bottom: 20%;
        }
      }
    }
  }
</style>
