<template>
  <div
    class="content-left-page"
    :class="{
      active: activePageIndex === index,
      changing: changingIndex === index,
      down: changingType === 'down' && changingIndex === index,
      up: changingType === 'up' && changingIndex === index,
      above: activePageIndex < index,
      below: activePageIndex > index,
    }"
  >
    <div class="clock-text">
      <div class="clock-text__content">
        <div class="overflow-hidden">
          <h4 class="clock-text__top-text animation">
            {{ page.clock.topText }}
          </h4>
        </div>
        <div class="overflow-hidden">
          <h2 class="clock-text__middle-text animation">
            {{ page.clock.middleText }}
          </h2>
        </div>
        <div class="overflow-hidden">
          <h4 class="clock-text__bottom-text animation"></h4>
        </div>
      </div>
    </div>
    <main class="main">
      <div v-if="page.isMain" class="company-info">
        <div class="company-info__title animation">{{ page.title }}</div>
        <div class="company-info__description">
          <div
            class="company-info__description_paragraph"
            v-for="(paragraph, idx) in page.descriptionList"
            :key="idx"
          >
            <span
              class="company-info__description_line animation"
              v-for="(str, index) in paragraph"
              :key="index"
            >
              {{ str }}
            </span>
          </div>
        </div>
      </div>

      <template v-else-if="page.isForm">
        <div class="main__content">
          <div class="overflow-hidden">
            <h2 class="main__title animation">Стоимость разработки</h2>
          </div>
          <div class="main__text-wrapper">
            <div class="overflow-hidden">
              <div class="main__text animation">
                Цена на услугу разработки начинается
                <b>от 1 млн. рублей.</b>
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="main__text animation">
                Конечная стоимость обсуждается при заключении
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="main__text animation">договора на оказание услуг.</div>
            </div>
          </div>
        </div>
        <div class="main__content">
          <div class="overflow-hidden">
            <h2 class="main__title animation">{{ page.projectsInfo[0].title }}</h2>
          </div>
          <div class="main__text-wrapper">
            <div class="overflow-hidden">
              <div class="main__text animation">
                Позвоните нам:
                <a href="tel:+79892224422">+7 989 222 44 22</a>
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="main__text animation">
                Напишите нам:
                <a href="mailto:admin@axas.ru">admin@axas.ru</a>
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="main__text animation">Мы обязательно ответим!</div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="main__content"
        v-for="(project, index) in page.projectsInfo"
        :key="index"
      >
        <div class="overflow-hidden">
          <h2 class="main__title animation">{{ project.title }}</h2>
        </div>
        <div class="main__text-wrapper">
          <div class="overflow-hidden">
            <div class="main__text animation">{{ project.firstLine }}</div>
          </div>
          <div class="overflow-hidden">
            <div class="main__text animation">
              {{ project.secondLine }}
            </div>
          </div>
          <div class="overflow-hidden">
            <div class="main__text animation">{{ project.thirdLine }}</div>
          </div>
        </div>
      </div>
      <div v-if="page.main.link" @click="linkHandler" class="overflow-hidden">
        <div class="main__link main__text animation button">
          <div class="link__text">
            {{ page.main.link.text }}
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import appConfig from '@/appConfig'

  export default {
    name: 'ContentLeftPage',
    props: {
      page: Object,
      index: Number,
    },
    computed: {
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
      pageHeight() {
        return this.$store.getters.pageHeight
      },
    },
    data() {
      return {
        changingType: null,
        changingIndex: null,
      }
    },
    methods: {
      linkHandler() {
        switch (this.page.main.link.type) {
          case 0:
            this.$store.commit('setActivePageIndex', appConfig.lastPageIndex)
            break
          case 1:
            window.location.href = 'https://axas.ru/portfolio/index.html'
            break
        }
      },
    },
    watch: {
      activePageIndex: {
        immediate: true,
        handler(newValue, oldValue) {
          this.changingIndex = oldValue

          if (oldValue < newValue) {
            this.changingType = 'up'
          } else {
            this.changingType = 'down'
          }
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  @keyframes inviting {
    0% {
    }
    100% {
      box-shadow: 1px 1px 1vw 1px rgba(#fff, 0.5), inset 0 0 0.25vw 1px rgba(#fff, 1);
    }
  }

  .content-left-page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
    transition: 0.7s;

    .animation {
      transition: 0.7s;
    }

    &:not(.active) {
      pointer-events: none;

      .button {
        opacity: 0;
      }
      .animation {
        opacity: 0;
      }
      .company-info {
        opacity: 0;
      }
      &.below {
        .animation {
          transform: translateY(-100%);
        }
      }
      &.above {
        .animation {
          transform: translateY(100%);
        }
      }
    }
    &.changing {
      &.up {
        .animation {
          transform: translateY(-100%);
        }
      }
      &.down {
        .animation {
          transform: translateY(100%);
        }
      }
    }

    .clock-text {
      position: relative;
      display: flex;
      justify-content: flex-end;
      height: 20vw;
      top: 10%;
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        max-width: min-content;
        z-index: 2;

        .overflow-hidden {
          width: 100%;
        }
      }

      &__middle-text {
        font-family: 'Roboto', sans-serif;
        font-size: 2.7vw;
        max-height: 3.5vw;
        max-width: 39vw;
        font-weight: 700;
        white-space: nowrap;
        margin-top: -0.5vw;
        z-index: 1;
      }
      &__bottom-text,
      &__top-text {
        white-space: nowrap;
        font-size: 1.3vw;
        min-height: 1.3vw;
        font-weight: 400;
        text-align: left;
      }
      &__top-text {
        width: 100%;
      }
      &__bottom-text {
        text-align: right;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 20vw;
      position: absolute;
      top: 50%;
      left: 17%;

      .company-info {
        &__title {
          font-family: 'Roboto', sans-serif;
          font-size: 2vw;
          font-weight: 700;
          white-space: nowrap;
          margin-bottom: 0.4vw;
        }
        &__description {
          &_paragraph {
            &:not(:first-child) {
              margin-top: 0.6vw;
            }
          }
          &_line {
            display: inline-block;
            font-size: 0.95vw;
            font-weight: 400;
            margin-right: 3vw;
            line-height: 1.5;
          }
        }
      }
      &__content {
        &:not(:first-child) {
          margin-top: 1vw;
        }
      }
      &__title {
        font-family: 'Roboto', sans-serif;
        font-size: 2vw;
        font-weight: 700;
        white-space: nowrap;
        margin-bottom: 0.6vw;
      }
      &__text {
        font-size: 0.95vw;
        font-weight: 400;
        white-space: nowrap;

        b {
          font-family: Roboto;
          font-size: 1.15vw;
          font-style: italic;
        }

        a {
          margin-left: 0.1vw;
          font-weight: 600;
          font-size: 1.05em;
          font-family: Roboto;
          letter-spacing: 0.1vw;
        }
      }
      .overflow-hidden {
        &:not(:first-child) {
          margin-top: 0.8vw;
        }
      }

      &__link {
        position: absolute;
        display: flex;
        align-items: center;
        height: 2vw;
        width: fit-content;
        z-index: 1;
        margin-top: 0.5vw;
        font-weight: bold;
        &.button {
          border: 1px solid #fff;
          border-radius: 0.5vw;
          padding: 1vw 1.5vw;
          transition: background-color 0.3s, transform 0.7s, opacity 0.7s;
          animation: 1.5s inviting infinite alternate;
          &:hover {
            animation: paused;
            background-color: rgba(#fff, 0.2);
          }
        }
        &:hover {
          cursor: pointer;
          .circle {
            stroke-dasharray: 100, 1000;
          }
          .arrow {
            transform: translateX(0.8vw);
          }
          .link__text {
          }
        }
        .circle {
          position: absolute;
        }
        .arrow {
          width: 1.8vw;
          transition: 0.4s;
          stroke-width: 1;
          stroke: rgb(255, 255, 255);
        }
        .circle {
          fill: none;
          width: 1.5vw;
          stroke: rgb(255, 255, 255);
          stroke-width: 4;
          stroke-miterlimit: 10;
          stroke-dasharray: 0, 1000;
          transition: 0.4s;
          border-radius: 50%;
        }
        .link__icons {
          position: relative;
          margin-left: 0.6vw;
          display: flex;
          align-items: center;
        }
        .link__text {
          font-size: 1.1em;
          transition: 0.4s;
        }
      }
    }
  }
</style>
