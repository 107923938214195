export default {
  pageList: [
    {
      id: 1,
      name: 'AVACore',
      'color-left': '#37a2d5',
      bgRight: '#006f86',
      isMain: true,
      title: 'AXAS',
      descriptionList: [
        [
          'Наша компания - разработчик и интегратор',
          'мобильных приложений и web-платформ.',
        ],
        ['В своей работе  мы ориентируемся на измеримый', ' результат.'],
        [
          'Богатый опыт работы и свежий взгляд на дизайн',
          'позволяют решать самые смелые задачи клиентов.',
        ],
        [
          'Мы можем предложить своим клиентам решения,',
          'отвечающие постоянно растущим стандартам',
          'надежности и дизайна.',
        ],
      ],
      clock: {
        topText: '',
        middleText: 'О компании',
        bottomText: '',
      },
      main: {
        link: {
          type: 0,
          text: 'Оставить заявку',
        },
      },
      mobile: {
        logo: require('@/assets/logonew.png'),
        bg: `url('${require('@/assets/Pages/AVACore/MobileBackground.webp')}')`,
        title: 'Больше чем студия разработки',
        description:
          'Наша компания — одна из ведущих в сфере разработки мобильных приложений и web-платформ. Богатый опыт работы вместе со свежим взглядом на дизайн позволяют создавать решения самых смелых и амбициозных задач клиентов.',
      },
      content: {
        moc1: require('@/assets/Pages/AVACore/1.png'),
        moc2: require('@/assets/Pages/AVACore/2.png'),
        moc3: require('@/assets/Pages/AVACore/3.png'),
        moc4: require('@/assets/Pages/AVACore/4.png'),
        moc5: require('@/assets/Pages/AVACore/5.png'),
      },
    },

    {
      id: 7,
      name: 'SocialWeb',
      clock: {
        topText: '',
        middleText: 'Социальные сети',
        bottomText: '',
      },
      'color-left': '#7836ce',
      bgRight: '#6a36ab',
      isProjectPage: true,
      projectsInfo: [
        {
          title: 'NotAlone',
          firstLine: 'Социальная сеть, позволяющая проводить онлайн',
          secondLine: 'встречи групп взаимопомощи и обмениваться',
          thirdLine: 'сообщениями в реальном времени.',
        },
        {
          title: '2Gether',
          firstLine: 'Мобильное приложение для поиска бизнес партнеров',
          secondLine: 'по заданным критериям:  бюджету, сфере деятельности',
          thirdLine: 'компетенциям, уделяемому времени и другим.',
        },
      ],
      main: {
        link: {
          type: 1,
          text: 'Посмотреть еще',
        },
      },
      content: {
        moc1: require('@/assets/Pages/SocialWeb/notalone1.webp'),
        moc2: require('@/assets/Pages/SocialWeb/2gether2.webp'),
        moc3: require('@/assets/Pages/SocialWeb/2gether1.webp'),
        moc4: require('@/assets/Pages/SocialWeb/notAlone2.webp'),
        moc5: require('@/assets/Pages/SocialWeb/notalone4.webp'),
      },
      mobile: {
        component: 'NotAlone',
        title: 'Социальные сети',
        titleStyles: 'text-shadow: 1px 1px 8px #414141; color: #fff',
        bg: 'linear-gradient(170deg,#8F49EA,#51307B)',
        platformList: [0, 1, 2],
      },
    },
    {
      id: 10,
      name: 'Payments',
      clock: {
        topText: '',
        middleText: 'Платежи и криптовалюты',
        bottomText: '',
      },
      'color-left': '#ee384e',
      bgRight: '#d0212d',
      isProjectPage: true,
      projectsInfo: [
        {
          title: 'KazakPay',
          firstLine: 'Мобильное приложение и веб-платформа, облегчающая',
          secondLine: 'платежи за подключенные сервисы, коммунальные услуги,',
          thirdLine: 'налоги и штрафы в республике Казахстан.',
        },
        {
          title: 'Avatars',
          firstLine: 'Новая мультиязычная социальная сеть с возможностью',
          secondLine: 'введения и выведения денежных средств, а также ',
          thirdLine: 'осуществления различных финансовых операций',
        },
      ],
      main: {
        link: {
          type: 1,
          text: 'Посмотреть еще',
        },
      },
      content: {
        moc1: require('@/assets/Pages/Payments/kazakPay1.webp'),
        moc2: require('@/assets/Pages/Payments/kazakPay2.webp'),
        moc3: require('@/assets/Pages/Payments/avatars1.webp'),
        moc4: require('@/assets/Pages/Payments/avatars2.webp'),
        moc5: require('@/assets/Pages/Payments/avatars3.webp'),
      },
      mobile: {
        component: 'PaymentsMobile',
        title: 'Платежи и криптовалюты',
        titleStyles: 'text-shadow: 1px 1px 8px #414141; color: #ff',
        bg: 'linear-gradient(170deg,#ee384e,#d0212d)',
        platformList: [0, 1, 2],
      },
    },
    {
      id: 4,
      name: 'Franklins',
      'color-left': '#f1a22f',
      bgRight: '#FFBB55',
      clock: {
        middleText: 'Приложения для бизнеса',
      },
      isProjectPage: true,
      projectsInfo: [
        {
          title: 'Франклинс бургер',
          firstLine: 'Удобное WEB приложение для просмотра товаров,',
          secondLine: 'акций, контактов и другой информаии о ресторане',
          thirdLine: 'с различных устройств.',
        },
        {
          title: 'AxasStore',
          firstLine: 'Легкий в использовании автоматизированный шаблон ',
          secondLine: 'для размещения и продажи различных товаров ',
          thirdLine: 'через мобильное приложение',
        },
      ],
      main: {
        link: {
          type: 1,
          text: 'Посмотреть еще',
        },
      },
      content: {
        moc1: require('@/assets/Pages/Business/moc1.webp'),
        moc2: require('@/assets/Pages/Business/franklins1.webp'),
        moc3: require('@/assets/Pages/Business/franklins2.webp'),
        table1: require('@/assets/Pages/Business/table1.webp'),
        moc4: require('@/assets/Pages/Business/moc2.webp'),
        moc5: require('@/assets/Pages/Business/axasStore2.webp'),
        table2: require('@/assets/Pages/Business/table2.webp'),
        bg: `url('${require('@/assets/Pages/Business/bg.webp')}')`,
      },
      mobile: {
        title: 'Прииложения для бизнеса',
        component: 'Franklins',
        titleStyles: 'text-shadow: 1px 1px 8px #414141; color: #fff',
        bg: `linear-gradient(283.26deg,#fca82f 4.87%,#FFBB55),linear-gradient(0deg,#fff,#bd1d1d);`,
        platformList: [3, 4],
      },
    },

    {
      id: 5,
      name: 'AVAResident',
      clock: {
        topText: ``,
        middleText: 'В разработке',
        bottomText: '',
      },
      'color-left': '#275486',
      bgRight: '#273e62',
      main: {
        title: 'Мой Краснодар',
        firstLine: '',
        secondLine: '',
        thirdLine: '',
        link: {
          type: 0,
          text: 'Оставить заявку',
        },
      },
      isProjectPage: true,
      projectsInfo: [
        {
          title: 'Мой Краснодар',
          firstLine: '“Мой Краснодар” - это платформа для жителей города, где ',
          secondLine: 'пользователи могут делиться новостями, событиями, ',
          thirdLine: 'фотографиями и отзывами о своем городе',
        },
      ],
      mobile: {
        component: 'AVAResidentMobile',
        title: '',
        image: require('@/assets/Pages/Resident/mobile-img.png'),
        bg: 'linear-gradient(to bottom right,#133B71,#273e62 40%,#133B71)',
        platformList: [0, 1],
      },
      content: {
        moc5: require('@/assets/Pages/Resident/1.png'),
        moc3: require('@/assets/Pages/Resident/2.png'),
        moc1: require('@/assets/Pages/Resident/3.png'),
        moc2: require('@/assets/Pages/Resident/4.png'),
        moc4: require('@/assets/Pages/Resident/5.png'),
      },
    },

    {
      id: 6,
      name: 'Team',
      clock: {
        topText: '',
        middleText: 'Наша команда',
        bottomText: '',
      },
      'color-left': '#163057',
      bgRight: '#0f1e34',
      main: {
        title: '',
        firstLine: '',
        secondLine: '',
        thirdLine: '',
        link: {
          type: 0,
          text: 'Оставить заявку',
        },
      },
      projectsInfo: [
        {
          title: 'О команде',
          firstLine: 'Мы создаем огромный спектр мобильных и web приложений,',
          secondLine: 'практикуем коллективный подход к решению задачи',
          thirdLine: 'и тесное взаимодействие с заказчиком.',
        },
      ],
      content: {},
      team: [
        {
          name: 'Майбродский Сергей',
          position: 'СЕО, Fullstack разработчик',
          photo: require('@/assets/Pages/Team/Sergey.webp'),
          competenceList: [
            'Управление и ведение проектов, команды, компании.',
            'Разработка WEB, Android и iOS приложений',
            'Написание логики серверной части приложения',
          ],
        },
        {
          name: 'Рудомаха Владимир',
          position: 'Backend разработчик',
          photo: require('@/assets/Pages/Team/Volodya.webp'),
          competenceList: [
            'Разработка и управление базами данных PostgreSQL',
            'Разработка серверных приложений на Flask и FastAPI',
            'Контейниризация с использованием Docker',
          ],
        },
        {
          name: 'Баджиев Артем',
          position: 'Frontend разработчик',
          photo: require('@/assets/Pages/Team/artem.webp'),
          competenceList: [
            'Разработка web-приложений с использованием framework’а Vue.js 3',
            'Работа с программным интерфейсом приложения',
            'Работа с редактором интерфейсов Figma',
          ],
        },
        {
          name: 'Минка Владимир',
          position: 'Backend разработчик',
          photo: require('@/assets/Pages/Team/minka.webp'),
          competenceList: [
            'Разработка backend части асинхронных приложений на FastAPI с использованием Pydantic, SQLAlchemy, Alembic.',
            'Работа с базами данных PostgreSQL, MySQL',
            'Интеграция с сторонними сервисами',
          ],
        },
        {
          name: 'Парфинчук Мария',
          position: 'QA-тестировщик',
          photo: require('@/assets/Pages/Team/masha.webp'),
          competenceList: [
            'Ручное тестирование мобильных приложений и веб-сайтов',
            'Исследовательское тестирование',
            'Составление баг-репортов',
            'Тестирование UI/UX',
          ],
        },
        {
          name: 'Татаринцев Алексей',
          position: 'IOS разработчик',
          photo: require('@/assets/Pages/Team/lesha.webp'),
          competenceList: [
            'Разработка мобильных приложений под IOS с использованием Swift',
            'Работа с REST API и библиотекой URLSession',
            'Управление многопоточностью приложения',
          ],
        },
        {
          name: 'Тупикин Павел',
          position: 'Android разработчик',
          photo: require('@/assets/Pages/Team/pasha.webp'),
          competenceList: [
            'Разработка android приложений на языке программирования Kotlin',
            'Поддержка и усовершенствование существующего кода',
            'Использование современного подхода к разработке ПО',
          ],
        },
        {
          name: 'Чупин Виталий',
          position: 'Android разработчик',
          photo: require('@/assets/Pages/Team/vitaly.webp'),
          competenceList: [
            'Разработка приложений для устройств с операционной системой Android',
            'Проектирование пользовательского интерфейса и взаимодействия с API',
            'Тестирование, отладка и оптимизация приложений',
          ],
        },
        {
          name: 'Эвери Владислав',
          position: 'Frontend разработчик',
          photo: require('@/assets/Pages/Team/every.webp'),
          competenceList: [
            'Создание хорошо работающих веб-приложений из красивых макетов',
            'Использование самых актуальных технологий - SASS, LESS',
            'Разработка сложного фронтенда в экосистеме Vue.js',
          ],
        },
      ],
      mobile: {
        component: 'TeamPageMobile',
        top_title: 'Наша команда',
        bg: 'linear-gradient(170deg,#0f1e34 10%,#163057 40%,#0f1e34)',
      },
    },
    {
      id: 3,
      name: 'ContactForm',
      'color-left': '#5082dc',
      bgRight: '#a7c4fa',
      isForm: true,
      clock: {
        topText: '',
        middleText: 'Оставить заявку',
        bottomText: '',
      },
      projectsInfo: [
        {
          title: 'Свяжитесь с нами',
          firstLine: 'Позвоните нам: +7 989 222 44 22',
          secondLine: 'Напишите нам: admin@axas.ru',
          thirdLine: 'Мы обязательно ответим!',
        },
        {
          title: 'Стоимость разработки',
          firstLine: 'Позвоните нам: +7 989 222 44 22',
          secondLine: 'Напишите нам: admin@axas.ru',
          thirdLine: 'Мы обязательно ответим!',
        },
      ],
      main: {
        title: 'Contact Form',
        firstLine: '',
        secondLine: '',
        thirdLine: '',
        link: {
          type: 1,
          text: 'Больше проектов',
        },
      },
      mobile: {
        title: '',
        component: 'FormPageMobile',
        // image: require('@/assets/examples/3.svg'),
        bg: '#00a2e1',
      },
    },
  ],
}
