<template>
  <div class="pre-loader">
    <svg
      class="pre-loader-icon"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      style="
        shape-rendering: geometricPrecision;
        text-rendering: geometricPrecision;
        image-rendering: optimizeQuality;
        fill-rule: evenodd;
        clip-rule: evenodd;
      "
      viewBox="0 0 2907 965"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_916573648">
          <path
            class="fil0"
            d="M2456 715c24,6 48,10 72,10l5 0c31,0 57,-5 79,-16 30,-14 45,-34 45,-59l0 -7c-3,-27 -21,-46 -54,-58 -10,-2 -38,-7 -82,-15 -52,-11 -97,-24 -133,-39 -106,-45 -159,-125 -159,-239 0,-109 54,-191 163,-246 47,-24 99,-37 155,-38 56,-1 116,9 180,30 74,24 126,56 156,96l-157 141c-25,-24 -47,-40 -68,-48 -20,-9 -42,-13 -65,-13 -75,0 -112,25 -112,74l0 3c0,15 10,28 31,40 21,11 80,25 175,42 147,28 220,118 220,270l0 5c0,56 -18,107 -55,152 -75,90 -180,134 -316,131l-80 -216z"
          />
          <path
            class="fil0"
            d="M2155 915l-50 -136c-93,110 -212,164 -357,164 -50,0 -98,-7 -143,-21l-311 -457 307 -431c50,-17 99,-26 149,-26 99,0 189,28 268,85 80,56 137,131 171,224l217 598 -251 0zm-538 -248c40,28 83,42 131,42 64,0 119,-22 165,-68 45,-44 68,-99 68,-164 0,-80 -33,-144 -100,-191 -39,-27 -84,-40 -133,-40 -81,0 -144,32 -189,98 -28,41 -42,85 -42,133 0,81 34,144 100,190z"
          />
          <path
            class="fil0"
            d="M619 920c-47,16 -98,23 -152,23 -99,0 -189,-28 -269,-84 -79,-56 -136,-130 -170,-223 -19,-50 -28,-103 -28,-159 0,-100 28,-189 84,-269 56,-80 130,-136 224,-170 53,-20 107,-30 161,-30 67,0 129,13 188,39l287 418 -325 455zm-283 -253c39,28 83,42 131,42 64,0 119,-22 164,-68 46,-44 68,-99 68,-164 0,-80 -33,-144 -99,-191 -40,-27 -84,-40 -133,-40 -82,0 -145,32 -189,98 -28,41 -42,85 -42,133 0,81 33,144 100,190z"
          />
          <polygon class="fil1" points="1286,965 939,965 1115,678 " />
          <polygon class="fil1" points="1292,0 1125,260 961,0 " />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'PreLoader',
    data() {
      return {
        content: ['AXAS'],
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fil1 {
    fill: #1a96ef;
  }
  .fil0 {
    fill: #1a96ef;
    fill-rule: nonzero;
  }

  .pre-loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: 1;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;

    svg {
      width: 15vw;
      height: 15vh;
      fill: #fff;
      transform: scale(1.6);
    }

    &.active {
      opacity: 1;
    }

    &__wave {
      position: absolute;
      content: '';
      top: 33%;
      left: 0;
      width: 100vw;
      height: 30vh;
      background: linear-gradient(to bottom right, #0f1e34, #183259);
      animation: animate 4s ease-in-out infinite;
    }
    &__logo {
      position: relative;
      background: linear-gradient(#fff, rgba(#0f1e34, 0.12), #fff);

      width: 100vw;
      height: 100vh;
      &:before {
        width: 100vw;
        position: absolute;
        top: 0;
        content: '';
        left: 0;
        background: #fff;
        height: 40%;
      }
      &:after {
        width: 100vw;
        position: absolute;
        bottom: 0;
        content: '';
        left: 0;
        background: #fff;
        height: 40%;
      }
      svg {
        width: 100vw;
        height: 100vh;
        fill: #fff;
        transform: scale(1.6);
      }

      @keyframes animate {
        0%,
        100% {
          transform: translate(5%);
          clip-path: polygon(
            0% 40%,
            16% 39%,
            33% 45%,
            54% 54%,
            70% 55%,
            84% 55%,
            100% 50%,
            100% 100%,
            0% 100%
          );
        }

        50% {
          transform: translate(-30%);
          clip-path: polygon(
            0% 60%,
            15% 65%,
            34% 66%,
            51% 62%,
            62% 50%,
            84% 40%,
            100% 35%,
            100% 100%,
            0% 100%
          );
        }
      }
    }
  }
</style>
