<template>
  <div class="clock">
    <div class="clock__container"></div>
    <svg
      class="ring"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 414 414"
      style="enable-background: new 0 0 414 414"
      xml:space="preserve"
    >
      <path
        class="ring__road"
        d="M84.2,85c31.3-31.5,74.7-51,122.7-51c95.5,0,173,77.5,173,173s-77.5,173-173,173
                c-47.8,0-91-19.4-122.3-50.7"
      ></path>
      <path
        class="ring__way"
        d="M84.2,85c31.3-31.5,74.7-51,122.7-51c95.5,0,173,77.5,173,173s-77.5,173-173,173
                c-47.8,0-91-19.4-122.3-50.7"
      ></path>
      <g
        :id="`Dots${index}`"
        v-for="index in [...Array(7).keys()]"
        @click="slideTo(index)"
        :key="index"
        class="dots-nav"
        :class="{ active: index === 0 || index <= activePageIndex }"
      >
        <g>
          <path
            d="M206.8,29c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S210.1,29,206.8,29z M206.8"
          ></path>
        </g>
        <g>
          <path
            d="M206.8,29c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S210.1,29,206.8,29z M206.8"
          ></path>
        </g>
      </g>
    </svg>
    <nav class="navigation">
      <div class="navigation__wrapper">
        <transition name="fade">
          <div
            v-if="activePageIndex !== 0"
            @click="slideTo(this.activePageIndex - 1)"
            class="navigation__button navigation__button_prev"
          >
            <svg
              class="arrow"
              viewBox="0 0 37 16"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.7071 8.7071C37.0976 8.31658 37.0976 7.68341 36.7071 7.29289L30.3431 0.92893C29.9526 0.538405 29.3195 0.538405 28.9289 0.92893C28.5384 1.31945 28.5384 1.95262 28.9289 2.34314L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.7071ZM8.74228e-08 9L36 9L36 7L-8.74228e-08 7L8.74228e-08 9Z"
              />
            </svg>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="activePageIndex !== lastPageIndex"
            @click="slideTo(this.activePageIndex + 1)"
            class="navigation__button navigation__button_next"
          >
            <svg
              class="arrow"
              viewBox="0 0 37 16"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.7071 8.7071C37.0976 8.31658 37.0976 7.68341 36.7071 7.29289L30.3431 0.92893C29.9526 0.538405 29.3195 0.538405 28.9289 0.92893C28.5384 1.31945 28.5384 1.95262 28.9289 2.34314L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.7071ZM8.74228e-08 9L36 9L36 7L-8.74228e-08 7L8.74228e-08 9Z"
              />
            </svg>
          </div>
        </transition>
      </div>
    </nav>
  </div>
</template>

<script>
  import appConfig from '@/appConfig'

  const wayValueList = [0, 150, 280, 405, 535, 680, 815]

  export default {
    name: 'TheClockScroll',
    data() {
      return {
        transitionDuration: appConfig.pageTransition,
        lastPageIndex: appConfig.lastPageIndex,
      }
    },
    computed: {
      wayValue() {
        return wayValueList[this.activePageIndex]
      },
      bg() {
        return this.pageList[this.activePageIndex]['color-left']
      },
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
      pageHeight() {
        return this.$store.getters.pageHeight
      },
      pageList() {
        return this.$store.getters.pageList
      },
    },
    methods: {
      slideTo(newValue) {
        if (newValue >= 0 && newValue <= this.lastPageIndex) {
          this.$store.commit('setActivePageIndex', newValue)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .clock {
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 28%;
    width: 20vw;
    height: 20vw;

    &__container {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: v-bind(bg);
      transition: background-color v-bind(transitionDuration);
      z-index: 1;
    }
    .ring {
      position: absolute;
      width: 20vw;
      height: 20vw;
      z-index: 5;

      &__road {
        opacity: 0.4;
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-miterlimit: 5;
        enable-background: new;
      }
      &__way {
        fill: none;
        stroke: rgb(255, 255, 255);
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke-dasharray: v-bind(wayValue), 1000;
        transition: 0.3s;
      }
    }
  }

  .dots-nav {
    position: relative;
    cursor: pointer;

    g {
    }

    &#Dots0 {
      transform: translate(-121px, 49px);
    }

    &#Dots1 {
      transform: translate(15px, -0.5px);
    }

    &#Dots2 {
      transform: translate(130px, 58px);
    }

    &#Dots3 {
      transform: translate(172.4px, 170px);
    }

    &#Dots4 {
      transform: translate(127.5px, 288.5px);
    }

    &#Dots5 {
      transform: translate(0.5px, 344.5px);
    }

    &#Dots6 {
      transform: translate(-119px, 297.45px);
    }

    &.active {
      g:first-of-type {
        transition: 0.5s;
        transition-delay: 0.3s;
        fill: $dot-active;
      }
      g:last-of-type {
        opacity: 1;
        fill: none;
        stroke: rgb(255, 255, 255);
        stroke-width: 2;
        stroke-miterlimit: 10;
        transition: 0.5s;
        transition-delay: 0.3s;
      }
    }
    &:not(.active) {
      g:first-of-type {
        fill: #ffffff;
        opacity: 0.4;
      }
      g:last-of-type {
        opacity: 0;
        fill: none;
      }
    }
  }
  .navigation {
    top: 60%;
    position: absolute;
    z-index: 5;
    width: 100%;
    &__wrapper {
      display: flex;
      margin-left: 5.3vw;
      height: 1vw;
    }
    &__button {
      display: block;
      svg {
        display: block;
        height: 100%;
        transition: 0.3s;
      }
      &_prev {
        transform: rotateZ(180deg);
        & + .navigation__button_next {
          margin: 0 1.38vw;
        }
      }
      &_next {
        margin-left: 3.7vw;
      }
      &:hover {
        svg {
          transform: scale(1.1);
        }
        cursor: pointer;
      }
    }
    &__text {
      font-size: 0.8vw;
      letter-spacing: 0.2vw;
    }
  }
</style>
