<template>
  <teleport to="#modal">
    <div v-if="isOpen" @click.self="closeHandler" class="modal">
      <transition name="scale">
        <div class="modal__wrapper" v-if="lazyIsOpen" :class="wrapperClass">
          <header class="header d-flex space-between align-center select-no">
            <h3 class="header__title">{{ title }}</h3>
            <div class="header__action">
              <svg
                @click="closeHandler"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L10 10M18 18L10 10M10 10L18 2L2 18"
                  stroke="#C2C2C2"
                  stroke-width="3"
                />
              </svg>
            </div>
          </header>
          <div class="content">
            <slot />
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
  import { onKeyStroke } from '@vueuse/core/index'
  export default {
    name: 'BaseModal',
    props: {
      isOpen: Boolean,
      closeable: {
        type: Boolean,
        default: true,
      },
      title: String,
      wrapperClass: String,
    },
    emits: ['close', 'trim'],
    data() {
      return {
        lazyIsOpen: this.isOpen,
      }
    },
    mounted() {
      onKeyStroke('Escape', () => this.$emit('close'), { eventName: 'keydown' })
    },
    methods: {
      closeHandler() {
        this.$emit('close')
        this.$emit('trim')
      },
    },
    watch: {
      isOpen: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            window.document.body.style.overflow = 'hidden'
          } else {
            window.document.body.style.overflow = ''
          }
          setTimeout(() => {
            this.lazyIsOpen = newValue
          }, 0)
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  .modal {
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to top left, rgba(#414141, 0.4), rgba(#222, 0.2) 150%);
    backdrop-filter: blur(2px);
    z-index: 1010;

    &__wrapper {
      max-width: 800px;
      min-width: 300px;
      background: #fafafa;
      border-radius: 16px;
      overflow: hidden;
      .header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;

        &__title {
          font-size: 1.5em;
          font-weight: normal;
          font-family: 'Halvar';
          display: flex;
          align-items: center;
          color: #212121;
        }
        &__action {
          display: flex;
          align-items: center;

          svg {
            cursor: pointer;
          }
        }
      }
      .content {
      }
    }
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: opacity 0.3s ease, transform 0.2s ease;
  }

  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.5);
  }
</style>
