<template>
  <header class="header">The Header</header>
</template>

<script>
  export default {
    name: 'TheHeader',
  }
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    height: var(--header-height);
    background: $header-bg;
  }
</style>
