<template>
  <div class="content-wrapper">
    <div class="content" @touchstart="onTouchStart" @touchend="onTouchEnd">
      <content-left :bg="pageList[activePageIndex]['color-left']" />
      <the-clock-scroll />
      <content-right :bg="pageList[activePageIndex].bgRight">
        <AVACore :class="{ active: activePageIndex === 0 }" />
        <SocialWeb :class="{ active: activePageIndex === 1 }" />
        <PaymentsPage :class="{ active: activePageIndex === 2 }" />
        <FranclinsPage :class="{ active: activePageIndex === 3 }" />
        <AVAResident :class="{ active: activePageIndex === 4 }" />
        <TeamPage :class="{ active: activePageIndex === 5 }" />
        <FormPage :class="{ active: activePageIndex === 6 }" />
      </content-right>
    </div>
  </div>
</template>

<script>
  import TheClockScroll from '@/components/Desktop/TheClockScroll'
  import ContentLeft from '@/components/Desktop/ContentLeft'
  import ContentRight from '@/components/Desktop/ContentRight'
  import SocialWeb from '@/components/Desktop/Pages/SocialWeb'
  import AVACore from '@/components/Desktop/Pages/AVACore'
  import PaymentsPage from '@/components/Desktop/Pages/PaymentsPage'
  import FranclinsPage from '@/components/Desktop/Pages/FranklinsPage'
  import AVAResident from '@/components/Desktop/Pages/AVAResident'
  import TeamPage from '@/components/Desktop/Pages/TeamPage'
  import FormPage from '@/components/Desktop/Pages/FormPage'

  export default {
    name: 'TheContent',
    components: {
      FormPage,
      TeamPage,
      AVAResident,
      FranclinsPage,
      PaymentsPage,
      AVACore,
      ContentRight,
      ContentLeft,
      TheClockScroll,
      SocialWeb,
    },
    data() {
      return {
        pageList: this.$store.getters.pageList,
        contentRightList: ['AVACore', '', '', '', '', '', 'NotAlone'],
        touchStartY: 0,
        touchEndY: 0,
      }
    },
    computed: {
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
    },
    methods: {
      onTouchStart(e) {
        this.touchStartY = e.changedTouches[0].screenY
      },
      onTouchEnd(e) {
        this.touchEndY = e.changedTouches[0].screenY
        this.swipeHandler()
      },
      swipeHandler() {
        if (this.touchStartY < this.touchEndY - 65) {
          // swiper up
          if (this.activePageIndex > 0) {
            this.$store.commit('setActivePageIndex', this.activePageIndex - 1)
          }
        }
        if (this.touchStartY > this.touchEndY + 65) {
          // swiper down
          if (this.activePageIndex < 6) {
            this.$store.commit('setActivePageIndex', this.activePageIndex + 1)
            this.$nextTick(() => {
              window.document.scrollingElement.scrollTop = 0
            })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    user-select: none;
    position: sticky;
    //top: var(--header-height);
    display: flex;
    //height: var(--content-height);
    height: 100vh;
    width: 100vw;
    top: 0;
    color: white;
    overflow: hidden;
    &-wrapper {
      height: 100vh;
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
</style>
