export default {
  pageTransition: '0.7s',
  lastPageIndex: 6,
  platformIconList: [
    { label: 'Android', img: require('@/assets/Platforms/android.svg') },
    { label: 'iOS', img: require('@/assets/Platforms/ios.svg') },
    { label: 'Web', img: require('@/assets/Platforms/web.svg') },
    { label: 'Desktop', img: require('@/assets/Platforms/desktop.svg') },
    { label: 'Mobile', img: require('@/assets/Platforms/phone.svg') },
  ],
}
