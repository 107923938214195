<template>
  <div class="page franklins">
    <div class="wrapper">
      <img class="moc" :src="page.content.moc1" alt="" />
      <img class="moc" :src="page.content.moc4" alt="" />
    </div>
    <div class="wrapper">
      <img class="table" :src="page.content.table1" alt="" />
      <img class="table" :src="page.content.table2" alt="" />
    </div>
  </div>
</template>

<script setup>
  import appContent from '@/appContent'

  const page = appContent.pageList.find((page) => page.name === 'Franklins')

  const bgImage = page.content.bg
</script>

<style scoped lang="scss">
  .page.franklins.active {
    .moc,
    .table {
      transform: none;
    }
  }

  .page {
    $table-size: 35vw;
    $size: 11.25vw;

    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    background-image: v-bind(bgImage);
    background-repeat: repeat;
    background-size: 25%;

    &:not(.active) {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
    .table {
      width: $table-size;
      position: absolute;
      object-fit: contain;
      border-radius: calc($table-size / 20);
      &:nth-child(1) {
        right: 2%;
        top: 3%;
        transform: translateY(-70vh);
        transition: 0.8s;
      }
      &:nth-child(2) {
        bottom: 3%;
        right: calc(4% + $size);
        transform: translateY(70vh);
        transition: 1.1s;
      }
    }
    .moc {
      position: absolute;
      width: $size;
      object-fit: contain;
      border-radius: calc($size / 5);

      &:nth-child(1) {
        top: 3%;
        right: calc(4% + $table-size);
        transform: translateX(-100vh);
        transition: 0.8s;
      }
      &:nth-child(2) {
        bottom: 3%;
        right: 2%;
        transform: translateX(70vh);
        transition: 1.1s;
      }
    }
  }
</style>
