<template>
  <textarea
    :key="key"
    ref="textarea"
    v-model="input"
    class="resize-none"
    placeholder="What's on your mind?"
  />
</template>

<script setup>
  import { useTextareaAutosize } from '@vueuse/core/index'
  import { watch, defineEmits, defineProps, ref, onUnmounted } from 'vue'

  const emit = defineEmits(['update:modelValue'])

  const { textarea, input } = useTextareaAutosize()

  const props = defineProps({
    modelValue: [String, null],
  })

  watch(
    () => input.value,
    (newValue, oldValue) => {
      emit('update:modelValue', newValue)
    }
  )
  watch(
    () => props.modelValue,
    (newValue, oldValue) => {
      if (newValue !== input.value) {
        input.value = newValue
      }
    }
  )
  const key = ref(false)
  const updateKey = () => {
    key.value = !key.value
  }
  window.addEventListener('resize', updateKey)
  onUnmounted(() => {
    window.removeEventListener('resize', updateKey)
  })
</script>

<script>
  export default {
    name: 'AutoresizeTextarea',
  }
</script>

<style scoped></style>
