import { createStore } from 'vuex'
import appContent from '@/appContent'
import notification from '@/store/notification'

export default createStore({
  state: {
    activePageIndex: 0,
    pageHeight: 937,
    isMobileFormCanSwipe: true,
    formData: {
      projectType: 0,
      budget: 0,
      description: null,
      file: null,
      name: null,
      tel: null,
      email: null,
      isAgree: false,
    },
  },
  getters: {
    activePageIndex: (state) => state.activePageIndex,
    pageHeight: (state) => state.pageHeight,
    pageList: () => appContent.pageList,
    formData: (state) => state.formData,
    isMobileFormCanSwipe: (state) => state.isMobileFormCanSwipe,
  },
  mutations: {
    setIsMobileFormCanSwipe(state, newValue) {
      state.isMobileFormCanSwipe = newValue
    },
    setActivePageIndex(state, newValue) {
      state.activePageIndex = newValue
    },
    setPageHeight(state, newValue) {
      state.pageHeight = newValue
    },
    updateFormData(state, newValue) {
      state.formData = {
        ...state.formData,
        ...newValue,
      }
    },
    clearFormData(state) {
      state.formData = {
        projectType: null,
        budget: null,
        description: null,
        file: null,
        contacts: {
          name: null,
          tel: null,
          email: null,
        },
        isAgree: false,
      }
    },
  },
  actions: {},
  modules: {
    notification,
  },
})
