<template>
  <div
    class="page-content"
    ref="el"
    :class="{
      active: activePageIndex === index,
      changing: changingIndex === index,
      down: changingType === 'down' && changingIndex === index,
      up: changingType === 'up' && changingIndex === index,
      above: activePageIndex < index,
      below: activePageIndex > index,
    }"
  >
    <div class="page-content__bg" :style="bgStyle"></div>
    <div v-if="page.mobile?.image || page.mobile?.title" class="page-content__wrapper">
      <div class="wrapper__content">
        <img
          v-if="page.mobile?.image"
          :src="page.mobile.image"
          class="page-content__image"
          alt=""
        />
        <div
          v-if="page.mobile?.title"
          class="page-content__title"
          :class="{ 'page-content__title_bg': !page.mobile.image }"
          :style="page.mobile.titleStyles"
        >
          {{ page.mobile.title }}
        </div>
      </div>
    </div>
    <component v-if="page.mobile.component" :is="page.mobile.component" />
    <page-text
      v-if="page?.projectsInfo?.length && page.isProjectPage"
      :projects-info="page.projectsInfo"
    />

    <ul v-if="page.mobile.platformList" class="platform-list">
      <li
        v-for="(platformIndex, index) in page.mobile.platformList"
        :key="platformIndex"
        class="platform-list__item platform"
        :style="{ 'transition-delay': index * 0.3 + 's' }"
      >
        <img class="platform__img" :src="platformIconList[platformIndex].img" alt="" />
        <div class="platform__label">
          {{ platformIconList[platformIndex].label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import appConfig from '@/appConfig'
  import PageText from '@/components/Mobile/PageText'

  const platformIconList = appConfig.platformIconList
</script>

<script>
  import NotAlone from '@/components/Mobile/Pages/SocialWebMobile'
  import CyberAdmin from '@/components/Mobile/Pages/CyberAdminMobile'
  import Franklins from '@/components/Mobile/Pages/FranklinsMobile'
  import AVAResidentMobile from '@/components/Mobile/Pages/AVAResidentMobile'
  import TeamPageMobile from '@/components/Mobile/Pages/TeamPageMobile'
  import PaymentsMobile from '@/components/Mobile/Pages/PaymentsMobile'
  import FormPageMobile from '@/components/Mobile/Pages/FormPageMobile'

  export default {
    name: 'PageContentMobile',
    components: {
      NotAlone,
      CyberAdmin,
      Franklins,
      AVAResidentMobile,
      TeamPageMobile,
      PaymentsMobile,
      FormPageMobile,
    },
    props: {
      page: Object,
      transformTitle: String,
      index: Number,
    },
    data() {
      return {
        isSwiping: false,
        direction: null,
        changingIndex: null,
        changingType: null,
        bgStyle: this.page.mobile.bg?.includes('url')
          ? `background-image: ${this.page.mobile.bg};`
          : `background: ${this.page.mobile.bg};`,
      }
    },
    computed: {
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
    },
    watch: {
      activePageIndex: {
        immediate: true,
        handler(newValue, oldValue) {
          this.changingIndex = oldValue

          if (oldValue < newValue) {
            this.changingType = 'up'
          } else {
            this.changingType = 'down'
          }
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-content {
    position: absolute;
    transition: opacity 0.5s;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .platform-list {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      bottom: 5%;
      z-index: 10005;

      .platform {
        display: flex;
        align-items: center;
        height: 40px;
        transform: none;
        transition: 0.5s;

        &__label {
          margin-left: 8px;
          font-size: calc(0.5vw + 8px);
        }
        &__img {
          width: 4vw;
          height: 4vw;
          object-fit: contain;
        }
        &:not(:first-child) {
          &:before {
            content: '•';
            font-size: 3vw;
            margin: 0 0.8rem;
          }
        }
      }
    }

    &.last {
      .page-content__title {
        transform: none;
      }
    }

    &:not(.active) {
      opacity: 0;
      z-index: -1;

      .platform {
        opacity: 0;
        transition: 0s !important;
      }

      &.below {
        .page-content__title {
          transform: translateY(-40px) scale(1.05);
        }
        .platform {
          transform: translateY(-8px);
        }
      }
      &.above {
        .page-content__title {
          transform: translateY(40px) scale(1.05);
        }
        .platform {
          transform: translateY(8px);
        }
      }
    }
    &.changing {
      &.up {
        .page-content__title {
          transform: translateY(-40px) scale(1.05);
        }
        .platform {
          transform: translateY(-8px);
        }
      }
      &.down {
        .page-content__title {
          transform: translateY(40px) scale(1.05);
        }
        .platform {
          transform: translateY(8px);
        }
      }
    }

    &__wrapper {
      position: absolute;
      width: 100%;
      z-index: 10001;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      .wrapper__content {
        pointer-events: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
      }
    }
    &__title {
      position: absolute;
      font-family: 'Halvar';
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 9vw;
      height: fit-content;
      width: 100%;
      transition: transform 0.5s, opacity 0.7s;
      transform: translateY(v-bind(transformTitle));

      &_bg {
        background: rgba(#414141, 0.35);
      }
    }
    &__image {
      object-fit: contain;
      width: 32vw;
      height: 32vw;
    }
    &__bg {
      background-size: cover;
      background-position: 50%;
      height: 100vh;
      width: 100vw;
    }
  }
  @media (max-width: 550px) {
    .page-content {
      &__image {
        width: 45vw;
        height: 45vw;
      }
    }
  }
</style>
