<template>
  <div class="page resident">
    <div class="timer" ref="timer"></div>
    <div class="wrapper">
      <img class="moc" :src="content.moc1" alt="" />
      <img class="moc" :src="content.moc2" alt="" />
      <img class="moc" :src="content.moc3" alt="" />
      <img class="moc" :src="content.moc4" alt="" />
      <img class="moc" :src="content.moc5" alt="" />
    </div>
  </div>
</template>
<script setup>
  import appContent from '@/appContent'

  const content = appContent.pageList.find((page) => page.name === 'AVAResident').content
</script>

<script>
  export default {
    name: 'AVAResident',
    data() {
      return {
        isMobile: window.innerWidth < 766,
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateIsMobile)
      if (!this.isMobile) {
        // const id = 'timer9711a614e002e2f7e4e73d00c512a5d3'
        const id = 'timera54c1352283122fb0b3407d6784b7715'
        const timer = document.getElementById(id)
        if (timer) {
          this.$refs.timer.appendChild(timer)
        }
      }
    },
    unmounted() {
      window.removeEventListener('resize', this.updateIsMobile)
    },
    methods: {
      updateIsMobile() {
        this.isMobile = window.innerWidth < 766
      },
    },
    watch: {
      isMobile: {
        handler(newValue) {
          if (!newValue) {
            const id = 'timera54c1352283122fb0b3407d6784b7715'
            const timer = document.getElementById(id)
            if (timer) {
              this.$refs.timer.appendChild(timer)
            }
          }
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  .page.resident {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: 0.7s;

    .timer {
      z-index: 1;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      &:hover {
        & + .wrapper {
          filter: blur(1px);
          &:after {
            background: rgba(#0e1e33, 0.5);
          }
        }
      }
    }
    .wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      filter: blur(8px);
      transition: 0.7s;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 0.7s;
        background: rgba(#0e1e33, 0.4);
      }
    }

    &:not(.active) {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
    &.active {
      .moc {
        transform: none !important;
      }
    }

    .moc {
      $size: 15vw;
      position: absolute;
      width: $size;
      object-fit: contain;
      border-radius: 3.3vw;

      &:nth-child(1),
      &:nth-child(2) {
        transform: translateY(-70vh);
      }
      &:nth-child(3),
      &:nth-child(4) {
        transform: translateY(70vh);
      }
      &:nth-child(5) {
        transform: translateX(70vh);
      }

      &:nth-child(1) {
        left: 13%;
        top: -13%;
        transition: 1s;
      }
      &:nth-child(2) {
        left: 40%;
        top: 5%;
        transition: 1.3s;
      }
      &:nth-child(3) {
        left: 13%;
        top: 37%;
        transition: 1.3s;
      }
      &:nth-child(4) {
        left: 40%;
        top: 50%;
        transition: 1s;
      }
      &:nth-child(5) {
        left: 67%;
        top: 30%;
        transition: 1s;
      }
    }
  }
</style>
