<template>
  <div class="page form">
    <contact-form is-mobile>
      <h1 class="form__title">Оставить заявку</h1>
    </contact-form>
  </div>
</template>
<script>
  import ContactForm from '@/components/Desktop/ContactForm'
  export default {
    name: 'FormPage',
    components: { ContactForm },
  }
</script>

<style scoped lang="scss">
  .active {
    .page.form {
      opacity: 1;
      z-index: 10000;
    }
  }

  .page {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    z-index: -1;
    opacity: 0;
  }
  .form {
    &__title {
      font-size: 4vw;
      margin-bottom: 0.6vw;
    }
  }
  @media (max-width: 500px) {
    .form {
      &__title {
        font-size: 7vw;
        margin-bottom: 3vw;
      }
    }
  }
</style>
