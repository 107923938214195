<template>
  <div class="wrapper">
    <div class="scroll">
      <div class="scroll__track"></div>
      <div class="scroll__bar"></div>
    </div>
  </div>
</template>

<script>
  import { useWindowScroll } from '@vueuse/core/index'
  import appConfig from '@/appConfig'
  const { y } = useWindowScroll()
  export default {
    name: 'TheMobileScroll',
    props: {
      currentScrollValue: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        scrollY: y,
        pageTransition: appConfig.pageTransition,
      }
    },
    mounted() {
      window.addEventListener('resize', this.updatePageHeight)
    },
    unmounted() {
      window.removeEventListener('resize', this.updatePageHeight)
    },
    computed: {
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
      pageList() {
        return this.$store.getters.pageList
      },
      pageHeight() {
        return this.$store.getters.pageHeight
      },
      scrollBGColor() {
        if (this.pageList[this.activePageIndex].mobile?.scroll?.bgColor) {
          return this.pageList[this.activePageIndex].mobile?.scroll?.bgColor
        } else {
          return '#ffffff3A'
        }
      },
      scrollBGColorBar() {
        if (this.pageList[this.activePageIndex].mobile?.scroll?.bgCoolorBar) {
          return this.pageList[this.activePageIndex].mobile?.scroll?.bgCoolorBar
        } else {
          return '#ffffffff'
        }
      },
      currentValue() {
        const scrollHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        )
        if (this.scrollY + this.pageHeight >= scrollHeight) {
          return 100 + '%'
        }
        const value = ((this.scrollY % this.pageHeight) / this.pageHeight) * 100
        this.$emit('update:currentScrollValue', value)
        return value + '%'
      },
    },
  }
</script>

<style scoped lang="scss">
  .wrapper {
    position: fixed;
    height: 100vh;
    width: min-content;
    display: flex;
    align-items: center;
    left: 2%;
    top: 0;
    z-index: 99999;
  }
  .scroll {
    height: 50vh;
    width: 5px;
    position: relative;
    z-index: 10000;

    &__track {
      height: 100%;
      width: 100%;
      background-color: v-bind(scrollBGColor);
      box-shadow: 1px 1px 4px rgba(#414141, 0.5);
    }
    &__bar {
      position: absolute;
      box-shadow: inset 0 0 2px 1px rgba(#414141, 0.2);
      top: 0;
      left: 0;
      width: 100%;
      height: v-bind(currentValue);
      background-color: v-bind(scrollBGColorBar);
      transition: 0.05s;
      z-index: 10;
    }
  }
</style>
