<template>
  <div @click="nextSlide" class="content-right">
    <transition name="fade">
      <contact-icons v-if="activePageIndex !== lastPageIndex" />
    </transition>
    <slot></slot>
  </div>
</template>

<script>
  import appConfig from '@/appConfig'
  import { mapGetters } from 'vuex'
  import ContactIcons from '@/components/Desktop/ContactIcons'

  export default {
    name: 'ContentRight',
    components: { ContactIcons },

    props: {
      bg: {
        type: String,
        default: '#09518a',
      },
    },
    data() {
      return {
        transitionDuration: appConfig.pageTransition,
        lastPageIndex: appConfig.lastPageIndex,
      }
    },
    methods: {
      nextSlide() {
        if (this.activePageIndex !== appConfig.lastPageIndex) {
          this.$store.commit('setActivePageIndex', this.activePageIndex + 1)
        }
      },
    },
    computed: {
      ...mapGetters(['activePageIndex']),
    },
  }
</script>

<style lang="scss" scoped>
  .content-right {
    position: relative;
    width: 66%;
    height: 100%;
    background-color: v-bind(bg);
    transition: background v-bind(transitionDuration);
  }
</style>
