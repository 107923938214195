<template>
  <div @click.stop class="slider desktop">
    <!--    <div @click="onPrev" class="slider__nav prev">-->
    <!--      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 330 330">-->
    <!--        <path-->
    <!--          id="XMLID_224_"-->
    <!--          d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"-->
    <!--        />-->
    <!--      </svg>-->
    <!--    </div>-->
    <swiper
      :modules="modules"
      :slidesPerView="3"
      :spaceBetween="40"
      class="mySwiper"
      :mousewheel="true"
      :navigation="true"
      :centeredSlides="true"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :loop="true"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="(item, index) in content" :key="index">
        <img class="swiper-img" :src="item.photo" :alt="item.name" />
        <div class="info">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="position">
            {{ item.position }}
          </div>
          <ul class="competence-list">
            <div class="competence-list__title">Навыки:</div>
            <li
              v-for="(comp, index) in item.competenceList"
              :key="index"
              class="competence-list__item"
            >
              {{ comp }}
            </li>
          </ul>
        </div>
      </swiper-slide>
    </swiper>
    <!--    <div @click="onNext" class="slider__nav next">-->
    <!--      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 330 330">-->
    <!--        <path-->
    <!--          id="XMLID_224_"-->
    <!--          d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"-->
    <!--        />-->
    <!--      </svg>-->
    <!--    </div>-->
  </div>
</template>
<script setup>
  import appContent from '@/appContent'

  const content = appContent.pageList.find((page) => page.name === 'Team').team
</script>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue'

  // Import Swiper styles
  import 'swiper/css'

  import { Autoplay, Controller, Mousewheel } from 'swiper'
  export default {
    name: 'TeamSlider',
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        modules: [Autoplay, Controller, Mousewheel],
        swiper: null,
      }
    },
    methods: {
      onSwiper(swiper) {
        this.swiper = swiper
      },
      onNext() {
        this.swiper.slideNext()
      },
      onPrev() {
        this.swiper.slidePrev()
      },
    },
  }
</script>

<style lang="scss">
  .slider.desktop {
    --size: 30vw;
    position: absolute;
    top: calc(50% - var(--size) / 2);
    left: 8.5vw;
    margin: 0 0.8vw;
    display: flex;
    align-items: center;
    border-radius: 0.5vw;
    overflow: hidden;
    background: rgba(grey, 0.2);

    &__nav {
      width: 2vw;
      height: var(--size);
      background: rgba(#fff, 0.05);
      color: #edf0f4;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s !important;
      &:hover {
        background: rgba(#fff, 0.15);
        color: #eff4fc;
      }
      &[disabled='true'] {
        color: rgba(#afafbf, 0.6);
        background: rgba(#a7adb7, 0.25);
      }

      &.next {
        margin-left: 4px;
        svg {
          transform: rotateZ(90deg);
        }
      }
      &.prev {
        margin-right: 4px;
        svg {
          transform: rotateZ(-90deg);
        }
      }
    }
    .mySwiper {
      width: 45vw;
      height: var(--size);

      .swiper-slide {
        position: relative;
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1vw 0;

        &:not(.swiper-slide-active) {
          filter: grayscale(20%);
        }
        &.swiper-slide-active {
          transform: scale(1.05);
        }
        .info {
          background: rgba(#fff, 0.95);
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          color: #0f1e34;
          width: 100%;
          .name {
            font-size: 0.9vw;
            padding: 0.8vw 0.6vw 0.2vw 0.6vw;
            font-weight: 500;
            font-family: Roboto;
            width: 100%;
          }
          .position {
            font-size: 0.8vw;
            padding: 0 0.6vw 0.7vw 0.6vw;
            border-bottom: 1px solid #0f1e34;
            font-family: Roboto;
            width: 100%;
          }
          .competence-list {
            padding: 0.7vw 0.4vw 0.4vw 0.6vw;

            &__title {
              align-self: flex-start;
              margin-bottom: 0.2vw;
              font-size: 0.7vw;
            }
            &__item {
              line-height: 1.4;
              list-style: circle;
              margin-left: 0.8vw;
              font-size: 0.65vw;

              &:not(:last-child) {
                margin-top: 0.2vw;
              }
            }
          }
        }
      }
    }
  }

  .swiper-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 0.5vw;
  }
</style>
