<template>
  <div @click.stop class="contact-icons" :class="[`color-${activePageIndex}`]">
    <a target="_blank" class="link telegram" href="https://t.me/iAxas">
      <svg
        class="icon"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
      >
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"
        />
      </svg>
    </a>
    <a target="_blank" class="link whatsapp" href="https://wa.me/79184167161">
      <svg
        class="icon"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
        />
      </svg>
    </a>
    <a @click="isOpenRequestModal = true" class="link request-call">
      <svg
        class="icon"
        fill="currentColor"
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 27.9999 51.9062 C 41.0546 51.9062 51.9063 41.0547 51.9063 28.0000 C 51.9063 14.9219 41.0312 4.0938 27.9765 4.0938 C 14.8983 4.0938 4.0937 14.9219 4.0937 28.0000 C 4.0937 41.0547 14.9218 51.9062 27.9999 51.9062 Z M 21.8827 33.8360 C 16.0702 28.0469 12.3671 20.6640 16.7499 16.2813 C 17.0077 16.0234 17.2890 15.7656 17.5468 15.5078 C 18.8827 14.2422 20.1718 14.3125 21.3202 15.9297 L 24.3671 20.2656 C 25.3983 21.7656 25.1405 22.6094 24.0390 23.7813 L 23.0780 24.8360 C 22.7265 25.1640 22.8671 25.6094 23.0312 25.8906 C 23.4765 26.7344 24.7421 28.2344 26.1014 29.5938 C 27.5077 31.0000 28.9374 32.1953 29.8280 32.6875 C 30.1562 32.875 30.6249 32.9219 30.9296 32.6406 L 31.9374 31.6797 C 33.0624 30.5781 33.9765 30.2969 35.4296 31.3281 C 37.4452 32.7578 38.6640 33.6016 39.8593 34.4219 C 41.3358 35.5000 41.6874 36.8360 40.1874 38.1953 C 39.9296 38.4531 39.6952 38.7344 39.4374 38.9922 C 35.0546 43.3516 27.6952 39.6484 21.8827 33.8360 Z"
        />
      </svg>
    </a>
    <call-modal :is-open="isOpenRequestModal" @close="isOpenRequestModal = false" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CallModal from '@/components/CallModal'

  export default {
    name: 'ContactIcons',
    components: { CallModal },

    computed: {
      ...mapGetters(['activePageIndex']),
    },
    data() {
      return {
        isOpenRequestModal: false,
      }
    },
  }
</script>

<style scoped lang="scss">
  .contact-icons:not(.mobile) {
    .icon {
      width: 2.1vw;
    }
  }
  .contact-icons {
    position: absolute;
    display: flex;
    z-index: 1000000000;
    top: 0;
    right: 0;
    width: 10vw;
    padding: 1vw;

    .request-call {
      transform: scale(1.1);
    }
  }
  .color-0 {
    color: #497d8a;
    .icon:hover {
      color: #106fa9;
    }
    .icon {
      stroke: #49718a;
      stroke-width: 3px;
      backdrop-filter: blur(4px);
    }
  }
  .color-1 {
    color: #d0ccff;

    .icon:hover {
      color: #efdfff;
    }
  }
  .color-2 {
    color: #ffcccc;
    .icon:hover {
      color: #ffdfdf;
    }
  }
  .color-3 {
    color: #f5e3c8;
    .link:not(.request-call) {
      .icon {
        stroke-width: 15px;
      }
    }

    .icon:hover {
      color: #fcc749;
    }
  }
  .color-4 {
    color: #96a7bb;
    .icon:hover {
      color: #d5dee8;
    }
  }
  .color-5 {
    color: #8698af;
    .icon:hover {
      color: #c1cbd7;
    }
  }
  .link {
    width: 33%;
    &:not(:first-child) {
      margin-left: 0.8vw;
    }
    &.request-call {
      .icon {
        transform: rotateY(180deg);
      }
    }
  }
  .icon {
    cursor: pointer;
    transition: 0.3s;
    stroke: #000;
    width: 100%;
    stroke-width: 1px;
  }
  a {
    display: flex;
  }

  .mobile {
    &.color-0 {
      color: #7383c0;
      .icon:hover {
        color: #adb0e0;
      }
      .icon {
        stroke: #8285c4;
        stroke-width: 3px;
        backdrop-filter: blur(4px);
      }
    }
    &.color-1 {
      color: #d0ccff;

      .icon:hover {
        color: #efdfff;
      }
      .link:not(.request-call) {
        .icon {
          stroke-width: 15px;
        }
      }
    }
    &.color-2 {
      color: #ffcccc;
      .icon:hover {
        color: #ffdfdf;
      }
      .link:not(.request-call) {
        .icon {
          stroke-width: 15px;
        }
      }
    }
    &.color-3 {
      color: #f5e3c8;
      .link:not(.request-call) {
        .icon {
          stroke-width: 15px;
        }
      }

      .icon:hover {
        color: #fcc749;
      }
    }
    &.color-4 {
      color: #96a7bb;
      .icon:hover {
        color: #d5dee8;
      }
    }
    &.color-5 {
      color: #8698af;
      .icon:hover {
        color: #c1cbd7;
      }
    }
  }

  @media (max-width: 767px) {
    .contact-icons {
      width: 32vw;
      padding: 5vw;
    }
    .link {
      &:not(:first-child) {
        margin-left: 1.6vw;
      }
    }
  }
</style>
