import { createApp } from 'vue'
import App from './App.vue'
import store from '@/store/index.js'
import router from '@/router/index.js'
import VueTheMask from 'vue-the-mask'
import VueYandexMetrika from 'vue3-yandex-metrika'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(VueTheMask)
  .use(VueYandexMetrika, {
    id: 90956505,
    router: router,
    env: process.env.NODE_ENV,
  })
  .mount('#app')
