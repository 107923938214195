<template>
  <div class="page">
    <contact-form />
  </div>
</template>

<script>
  import ContactForm from '@/components/Desktop/ContactForm'
  export default {
    name: 'FormPage',
    components: { ContactForm },
  }
</script>

<style scoped lang="scss">
  .page {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.7s;
    &:not(.active) {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
  }
</style>
