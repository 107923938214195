<template>
  <BaseModal :is-open="isOpen" :title="title || 'Заказать звонок'">
    <div class="d-flex flex-column call-form">
      <div class="call-form__item">
        <div class="call-form__item_input">
          <input
            type="text"
            class="input"
            :class="{ invalid: wasCheck && !name }"
            v-model="name"
            placeholder="Как вас зовут?"
          />
          <span v-if="wasCheck && !name" class="error-message">Введите имя</span>
        </div>
      </div>
      <div class="call-form__item">
        <div class="call-form__item_input">
          <input
            type="text"
            class="input tel-input"
            :class="{ invalid: wasCheck && !tel }"
            v-mask="'+7-(###)-###-##-##'"
            :value="tel"
            placeholder="Телефон"
            @change="updatePhoneValue($event.target.value)"
          />
          <span v-if="wasCheck && !tel" class="error-message">Введите телефон</span>
          <span v-if="wasCheck && tel && tel.length !== 11" class="error-message">
            Введите корректный телефон
          </span>
        </div>
      </div>
      <div class="call-form__item">
        <div class="call-form__item_input">
          <autoresize-textarea placeholder="Комментарий" v-model="comment" />
        </div>
      </div>

      <div class="call-form__item">
        <div class="politics" data-v-12c0c98c="">
          <div
            class="politics__checkbox-wrapper"
            :class="{ invalid: wasCheck && !isAgree }"
            @click="isAgree = !isAgree"
          >
            <div class="politics__checkbox" :class="{ active: isAgree }"></div>
          </div>
          <div class="politics__text" data-v-12c0c98c="">
            Я согласен на
            <a
              href="/политика персональных данных.pdf"
              data-v-12c0c98c=""
              target="_blank"
              style="text-decoration: underline; color: rgb(62, 62, 62)"
            >
              обработку персональных данных
            </a>
          </div>
        </div>
      </div>

      <div class="call-form__action">
        <div @click="sendForm" class="button">Отправить заявку</div>
      </div>
    </div>
  </BaseModal>
</template>
<script>
  // eslint-disable-next-line no-unused-vars
  import axios from 'axios'
  import BaseModal from '@/components/BaseModal'
  import AutoresizeTextarea from '@/components/AutoresizeTextarea'

  export default {
    name: 'CallModal',
    components: { AutoresizeTextarea, BaseModal },
    props: {
      isOpen: Boolean,
      title: String,
    },

    data() {
      return {
        isAgree: false,
        tel: null,
        name: null,
        comment: null,
        wasCheck: false,
      }
    },
    computed: {
      isValidForm() {
        return this.tel && this.tel.length === 11 && this.name && this.isAgree
      },
    },
    methods: {
      updatePhoneValue(value) {
        let newNumber = ''
        for (let char of value) {
          if ('0123456789'.includes(char)) {
            newNumber += char
          }
        }
        this.tel = newNumber
      },
      sendForm() {
        this.wasCheck = true
        if (this.isAgree && this.isValidForm) {
          const formData = new FormData()
          formData.append('name', JSON.stringify(this.name))
          formData.append('phone', JSON.stringify('+' + this.tel))
          formData.append('comment', JSON.stringify(this.comment))
          formData.append('site', 'AVACore')

          const promises = []
          promises.push(
            axios
              .post('https://axas.ru/portfolio/api/call_request.php', formData)
              .then(() => {
                this.tel = null
                this.name = null
                this.wasCheck = false
                this.isAgree = false
                this.comment = null
              })
              .catch(() => {})
              .finally(() => {
                this.$emit('close')
              })
          )

          promises.push(
            axios.get(
              `https://axas.bitrix24.ru/rest/1/v43ow0q8h42amca5/crm.lead.add.json?FIELDS[TITLE]=AVACore:call&FIELDS[NAME]=${encodeURIComponent(
                this.name
              )}&FIELDS[EMAIL][0][VALUE]=mail@exampe.com&FIELDS[PHONE][0][VALUE]=${
                '%2B' + this.tel
              }&FIELDS[OPPORTUNITY]=0&FIELDS[CURRENCY]=RUB&FIELDS[COMMENTS]=${encodeURIComponent(
                this.comment
              )}`
            )
          )

          Promise.any(promises)
            .then(() => {
              this.$store.commit('notification/setNotification', {
                isShow: true,
                title: 'Заявка успешно отправлена!',
                subtitle: 'Мы свяжемся с вами в ближайшее время.',
              })
            })
            .catch(() => {
              this.$store.commit('notification/setNotification', {
                title: 'Не удалось отправить заявку',
                subtitle: 'Воспользуйтесь контактами или попробуйте позже.',
                isShow: true,
              })
            })
        }
      },
    },
  }
</script>
<style lang="scss">
  .error-message {
    color: red;
    font-size: 0.75vw;
    line-height: 1.4;
    padding: 0.4vw;
  }

  .call-form {
    padding: 8px 24px 24px 24px;
    $black: #212121;
    height: 100%;
    color: $black;
    background: #fff;
    --el-padding-horizontal: 1.4vw;
    --el-padding-vertical: 0.8vw;
    --el-padding: var(--el-padding-vertical) var(--el-padding-horizontal);
    --el-margin: 0.65vw;
    --font-size-larger: 1.15vw;
    --font-size-middle: 16px;
    --font-size-lesser: 14px;

    .ml {
      margin-left: var(--el-margin);
    }
    .mt {
      margin-top: var(--el-margin);
    }
    .contacts {
      .input {
        width: 100%;
      }
    }
    &__action {
      margin-top: 8px;
      display: flex;
      justify-content: center;

      .button {
        text-align: center;
        border: 2px solid rgba($black, 0.2);
        padding: 12px 24px;
        font-size: var(--font-size-middle);
        transition: 0.3s;
        font-family: 'Roboto', sans-serif;

        &:hover {
          cursor: pointer;
          border: 2px solid rgba($black, 1);
        }
      }
    }
    &__item {
      &_input {
        display: flex;
        flex-direction: column;

        .input.invalid {
          animation: 0.3s 2 blink forwards;
        }

        .input-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          &:not(:first-child) {
            margin-left: var(--el-margin);
          }
        }

        &.type-list {
          flex-wrap: wrap;
        }

        & + & {
          margin-top: var(--el-margin);
        }

        textarea {
          width: 100%;
          min-height: 43px;
          overflow-y: hidden;
          font-size: var(--font-size-lesser);
          padding: var(--el-padding-vertical);
          border: 2px solid rgba($black, 0.2);
          vertical-align: center;
          &:focus {
            border: 2px solid $black;
          }
        }

        input {
          width: 100%;
          padding: var(--el-padding-vertical);
          font-size: var(--font-size-lesser);
          border: 2px solid rgba($black, 0.2);
          display: flex;

          &:focus {
            border: 2px solid $black;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: var(--el-margin);
      }

      .politics {
        margin-top: calc(var(--el-margin) / 2);
        display: flex;
        align-items: center;
        &__checkbox-wrapper {
          padding: calc(var(--el-padding-horizontal) / 2);
          background: rgba($black, 0.05);
          width: fit-content;
          border: 2px solid transparent;

          &.invalid {
            background: #fff;
            animation: 0.3s 2 blink forwards;
          }
        }
        &__checkbox {
          padding: calc(var(--el-padding-vertical) / 2);
          background: rgba($black, 0.15);

          &.active {
            background: #6f8dc2;
          }
        }
        &__text {
          font-size: var(--font-size-middle);
          margin-left: var(--el-margin);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .error-message {
      padding: 0.8vw;
      font-size: 2.1vw;
    }
    .call-form {
      max-width: 100vw;
      left: 0;
      margin: 0;
      border-radius: 0;
      $black: #212121;
      padding: 2.5vw 5vw;
      --el-padding-horizontal: 3vw;
      --el-padding-vertical: 2vw;
      --el-padding: var(--el-padding-vertical) var(--el-padding-horizontal);
      --el-margin: 2vw;
      --font-size-larger: 3vw;
      --font-size-middle: 2.2vw;
      --font-size-lesser: 2vw;
    }
  }

  @media (max-width: 500px) {
    .error-message {
      font-size: 3vw;
    }
    .call-form {
      border-radius: 0;
      $black: #212121;
      padding: 5vw;
      --el-padding-horizontal: 4.2vw;
      --el-padding-vertical: 3vw;
      --el-padding: var(--el-padding-vertical) var(--el-padding-horizontal);
      --el-margin: 4.5vw;
      --font-size-larger: 5vw;
      --font-size-middle: 4.3vw;
      --font-size-lesser: 4vw;
      .form__item_input {
        .input-wrapper {
          &:not(:first-child) {
            margin-left: 0;
            margin-top: var(--el-margin);
          }
        }
      }
    }
  }

  @keyframes blink {
    0% {
      border: 2px solid rgba(red, 0.6);
    }
    50% {
      border: 2px solid transparent;
    }
    100% {
      border: 2px solid rgba(red, 0.6);
    }
  }
</style>
