<template>
  <div class="page resident">
    <div class="wrapper">
      <img class="moc" :src="content.moc1" alt="" />
      <img class="moc" :src="content.moc2" alt="" />
      <img class="moc" :src="content.moc3" alt="" />
      <img class="moc" :src="content.moc4" alt="" />
      <img class="moc" :src="content.moc5" alt="" />
    </div>
    <div class="timer"><div class="timer__wrapper" ref="timerMobile"></div></div>
  </div>
</template>
<script setup>
  import appContent from '@/appContent'

  const content = appContent.pageList.find((page) => page.name === 'AVAResident').content
</script>

<script>
  export default {
    name: 'AVAResidentMobile',
    data() {
      return {
        isMobile: window.innerWidth < 766,
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateIsMobile)
      if (this.isMobile) {
        const id = 'timera54c1352283122fb0b3407d6784b7715'
        const timer = document.getElementById(id)
        if (timer) {
          this.$refs.timerMobile.appendChild(timer)
        }
      }
    },
    unmounted() {
      window.removeEventListener('resize', this.updateIsMobile)
    },
    methods: {
      updateIsMobile() {
        this.isMobile = window.innerWidth < 766
      },
    },
    watch: {
      isMobile: {
        handler(newValue) {
          if (newValue) {
            const id = 'timera54c1352283122fb0b3407d6784b7715'
            const timer = document.getElementById(id)
            if (timer) {
              this.$refs.timerMobile.appendChild(timer)
            }
          }
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  .active {
    .page.resident {
      z-index: 10000;
      .moc {
        transform: translateY(0);
      }
    }
  }

  .page {
    overflow: hidden;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;

    .timer {
      z-index: 9999999;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &__wrapper {
        position: relative;
        bottom: 30%;
        height: fit-content;
        width: fit-content;
      }
    }

    .wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      filter: blur(5px);
      transition: 0.7s;

      &:hover {
        filter: blur(1px);
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 0.7s;
        background: rgba(#0e1e33, 0.4);
      }

      &:first-child {
        z-index: 9998;
      }
      &:last-child {
        z-index: 9997;
      }
    }

    .moc {
      $size: 25vw;

      position: absolute;
      width: $size;
      object-fit: contain;
      border-radius: 3.3vw;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        transform: translateY(-70vh);
      }

      &:nth-child(1) {
        left: 10%;
        top: 2%;
        transition: 0.7s;
      }
      &:nth-child(3) {
        left: calc(50% - $size / 2);
        top: 5%;
        transition: 0.7s;
      }
      &:nth-child(2) {
        right: 10%;
        top: 2%;
        transition: 0.7s;
      }
      &:nth-child(4) {
        right: 20%;
        top: calc(50% - $size / 2);
        transition: 1.5s;
      }
      &:nth-child(5) {
        left: 20%;
        top: calc(50% - $size / 2);
        transition: 1.5s;
      }
    }
  }

  @media (max-width: 550px) {
    .page {
      .moc {
        $size: 30vw;
        width: $size;
        &:nth-child(1) {
          left: 5%;
          top: 4%;
        }
        &:nth-child(3) {
          top: 8%;
          left: calc(50% - $size / 2);
        }
        &:nth-child(2) {
          right: 5%;
          top: 4%;
        }
        &:nth-child(4) {
          right: 12%;
          bottom: 20%;
        }
        &:nth-child(5) {
          left: 12%;
          bottom: 20%;
        }
      }
    }
  }
</style>
