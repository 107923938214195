<template>
  <div class="content-left">
    <div class="logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        width="39.0165mm"
        height="12.9454mm"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 2680 889"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Слой_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <g id="_916566800">
            <path
              class="fil0"
              d="M2264 658c21,7 43,10 66,10l5 0c28,0 52,-5 72,-15 28,-12 42,-31 42,-54l0 -6c-3,-25 -20,-43 -50,-54 -9,-2 -35,-7 -76,-14 -48,-10 -89,-22 -122,-35 -98,-42 -147,-116 -147,-221 0,-100 50,-176 150,-226 44,-23 92,-35 144,-36 51,-1 106,8 166,28 67,22 115,52 143,88l-145 131c-23,-23 -43,-38 -62,-45 -19,-8 -39,-11 -60,-11 -69,0 -104,22 -104,67l0 3c0,14 10,26 29,37 20,10 73,23 162,39 135,26 203,109 203,248l0 5c0,52 -18,98 -52,140 -68,84 -165,124 -291,121l-73 -200z"
            />
            <path
              class="fil0"
              d="M1986 844l-46 -126c-86,101 -195,151 -329,151 -46,0 -90,-6 -132,-20l-287 -421 283 -397c46,-16 92,-24 138,-24 91,0 174,26 247,79 74,52 126,121 157,207l200 551 -231 0zm-496 -229c37,26 77,38 121,38 59,0 110,-20 152,-62 42,-41 63,-92 63,-151 0,-74 -31,-133 -92,-176 -37,-25 -78,-37 -123,-37 -75,0 -133,30 -174,90 -26,38 -38,79 -38,123 0,74 30,133 91,175z"
            />
            <path
              class="fil0"
              d="M570 848c-43,14 -90,21 -140,21 -91,0 -174,-25 -247,-77 -74,-52 -126,-120 -157,-206 -17,-46 -26,-95 -26,-147 0,-91 26,-174 77,-247 52,-74 121,-126 207,-157 49,-19 98,-28 148,-28 62,0 119,12 173,36l265 385 -300 420zm-261 -233c37,26 77,38 121,38 59,0 110,-20 152,-62 42,-41 63,-92 63,-151 0,-74 -31,-133 -92,-176 -37,-25 -78,-37 -123,-37 -75,0 -133,30 -174,90 -26,38 -38,79 -38,123 0,74 30,133 91,175z"
            />
            <polygon class="fil1" points="1185,889 865,889 1028,625 " />
            <polygon class="fil1" points="1191,0 1036,240 886,0 " />
          </g>
        </g>
      </svg>
      <a class="contact-phone" href="tel:+79892224422">+7 989 222 44 22</a>
      <a class="contact-phone" href="mailto:admin@axas.ru">admin@axas.ru</a>
      <p class="contact-phone">350051 г. Краснодар ул. Монтажников 1/4 оф. 1205</p>
      <p class="contact-phone">ОГРНИП: 317237500402141</p>
      <p class="contact-phone">ИНН: 233710743508</p>
      <!--<a class="contact-phone" href="/certificate.pdf" target="_blank">
        Запатентованный продукт
      </a>-->
    </div>

    <content-left-page
      v-for="(page, index) in pageList"
      :key="page.id"
      :page="page"
      :index="index"
    />
  </div>
</template>

<script>
  import appConfig from '@/appConfig'
  import { onKeyStroke } from '@vueuse/core'
  import ContentLeftPage from '@/components/Desktop/ContentLeftPage'
  export default {
    name: 'ContentLeft',
    components: { ContentLeftPage },

    props: {
      bg: {
        type: String,
        default: '#0f1e34',
      },
    },
    data() {
      return {
        isShow: true,
        transitionDuration: appConfig.pageTransition,
        lastPageIndex: appConfig.lastPageIndex,
      }
    },
    computed: {
      pageList() {
        return this.$store.getters.pageList
      },
      pageHeight() {
        return this.$store.getters.pageHeight
      },
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
    },
    created() {
      onKeyStroke('ArrowLeft', () => {
        if (this.activePageIndex !== 0)
          this.updateActivePageIndex(this.activePageIndex - 1)
      })
      onKeyStroke('ArrowRight', () => {
        if (this.activePageIndex !== appConfig.lastPageIndex)
          this.updateActivePageIndex(this.activePageIndex + 1)
      })
    },
    methods: {
      updateActivePageIndex(newValue) {
        if (newValue >= 0 && newValue <= appConfig.lastPageIndex) {
          this.$store.commit('setActivePageIndex', newValue)
        }
      },
    },
    watch: {
      activePageIndex() {
        this.isShow = false
        this.$nextTick(() => {
          this.isShow = true
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fil1 {
    fill: #fefefe;
  }
  .fil0 {
    fill: #fefefe;
    fill-rule: nonzero;
  }

  .content-left {
    position: relative;
    width: 44%;
    height: 100%;
    background-color: v-bind(bg);
    transition: background-color v-bind(transitionDuration);
    overflow: hidden;

    .logo {
      display: flex;
      flex-direction: column;
      padding: 2vw 2vw 1vw;
      max-width: 400px;
      &__svg {
        width: 3.5vw;
      }
    }
    .contact-phone {
      margin-top: 0.5vw;
      font-size: 0.7vw;
      z-index: 1000000;
    }
    .contact-phone + .contact-phone {
      margin-top: 0.45vw;
    }
  }
</style>
