<template>
  <div class="page-slider">
    <div
      class="page-slide"
      @touchstart="onTouchStart($event)"
      @touchend="onTouchEnd($event)"
      v-for="(page, idx) in pageList"
      :key="page.id"
      :class="{ 'active-slide': activePageIndex === idx }"
    >
      <main-page-mobile v-if="idx === 0" :class="{ active: activePageIndex === 0 }" />
      <page-content-mobile
        v-else
        :transform-title="'0'"
        :index="idx"
        :page="page"
        :class="{ last: idx === pageList.length - 1 }"
      />
    </div>
  </div>
</template>

<script>
  import PageContentMobile from '@/components/Mobile/PageContentMobile'
  import MainPageMobile from '@/components/Mobile/Pages/MainPageMobile'
  export default {
    name: 'PageSliderMobile',
    components: { MainPageMobile, PageContentMobile },
    props: {
      modelValue: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        touchStartY: 0,
      }
    },
    computed: {
      pageList() {
        return this.$store.getters.pageList
      },
      activePageIndex() {
        return this.$store.getters.activePageIndex
      },
      transformTitle() {
        return 0
      },
      isMobileFormCanSwipe() {
        return this.$store.getters.isMobileFormCanSwipe
      },
    },
    methods: {
      onTouchStart(e) {
        this.touchStartY = e.changedTouches[0].screenY
      },
      onTouchEnd(e) {
        this.touchEndY = e.changedTouches[0].screenY
        this.swipeHandler()
      },
      swipeHandler() {
        if (this.touchStartY < this.touchEndY - 65) {
          // console.log('Swiped up')
          if (this.activePageIndex > 0) {
            // Check page is formPage and form.scrollTop === 0
            if (this.activePageIndex === 6 && !this.isMobileFormCanSwipe) {
              return
            }
            this.$store.commit('setActivePageIndex', this.activePageIndex - 1)
          }
        }

        if (this.touchStartY > this.touchEndY + 65) {
          // console.log('Swiped down')
          if (this.activePageIndex < 6) {
            this.$store.commit('setActivePageIndex', this.activePageIndex + 1)
            this.$nextTick(() => {
              window.document.scrollingElement.scrollTop = 0
            })
          }
        }
        // setTimeout(() => {
        //   window.document.scrollingElement.scrollTop = 0
        // }, 300)
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-slider {
    width: 100vw;
    height: 100vh;

    .page-slide {
      position: absolute;
      height: 100%;
      width: 100%;

      &:not(.active-slide) {
        pointer-events: none;
      }
    }
  }
</style>
