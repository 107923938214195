<template>
  <div class="page cyber-admin">
    <div class="wrapper">
      <img class="moc" :src="content.moc2" alt="" />
      <img class="moc" :src="content.moc1" alt="" />
      <img class="moc" :src="content.moc3" alt="" />
    </div>
    <div class="wrapper">
      <img class="ball" :src="content.ball" alt="" />
      <img class="ball" :src="content.ball" alt="" />
      <img class="gamepad" :src="content.gamepad" alt="" />
    </div>
  </div>
</template>

<script setup>
  import appContent from '@/appContent'

  const content = appContent.pageList.find((page) => page.name === 'CyberAdmin').content
</script>

<script>
  export default {
    name: 'CyberAdminMobile',
  }
</script>

<style scoped lang="scss">
  .active {
    .page.cyber-admin {
      .moc {
        transform: translateY(0);
      }
    }
  }

  .page {
    overflow: hidden;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    .wrapper {
      position: absolute;
      height: 100%;
      width: 100%;

      &:first-child {
        z-index: 9998;
      }
      &:last-child {
        z-index: 9997;
      }
    }

    .moc {
      position: absolute;
      width: 25vw;
      object-fit: contain;
      border-radius: 3.3vw;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        transform: translateY(-70vh);
      }

      &:nth-child(1) {
        left: 10%;
        top: 2%;
        transition: 0.7s;
      }
      &:nth-child(3) {
        left: calc(50% - 12.5vw);
        top: 5%;
        transition: 1s;
      }
      &:nth-child(2) {
        right: 10%;
        top: 2%;
        transition: 0.7s;
      }
    }

    .ball {
      $size: 25vw;
      position: absolute;
      width: $size;

      &:nth-child(1) {
        top: 48%;
        left: 8%;
      }
      &:nth-child(2) {
        right: 8%;
        top: 40%;
      }
    }

    .gamepad {
      $size: 25vw;
      position: absolute;
      bottom: 25%;
      left: calc(60% - $size / 2);
      width: $size;
    }
  }

  @media (max-width: 550px) {
    .page {
      .moc {
        $size: 30vw;
        width: 30vw;
        &:nth-child(1) {
          left: 5%;
          top: 4%;
        }
        &:nth-child(3) {
          top: 8%;
          left: calc(50% - $size / 2);
        }
        &:nth-child(2) {
          right: 5%;
          top: 4%;
        }
      }
    }
  }
</style>
