<template>
  <div class="page main">
    <div class="background"></div>
    <div class="logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        width="39.0165mm"
        height="12.9454mm"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 2680 889"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs></defs>
        <g id="Слой_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <g id="_916566800">
            <path
              class="fil0"
              d="M2264 658c21,7 43,10 66,10l5 0c28,0 52,-5 72,-15 28,-12 42,-31 42,-54l0 -6c-3,-25 -20,-43 -50,-54 -9,-2 -35,-7 -76,-14 -48,-10 -89,-22 -122,-35 -98,-42 -147,-116 -147,-221 0,-100 50,-176 150,-226 44,-23 92,-35 144,-36 51,-1 106,8 166,28 67,22 115,52 143,88l-145 131c-23,-23 -43,-38 -62,-45 -19,-8 -39,-11 -60,-11 -69,0 -104,22 -104,67l0 3c0,14 10,26 29,37 20,10 73,23 162,39 135,26 203,109 203,248l0 5c0,52 -18,98 -52,140 -68,84 -165,124 -291,121l-73 -200z"
            />
            <path
              class="fil0"
              d="M1986 844l-46 -126c-86,101 -195,151 -329,151 -46,0 -90,-6 -132,-20l-287 -421 283 -397c46,-16 92,-24 138,-24 91,0 174,26 247,79 74,52 126,121 157,207l200 551 -231 0zm-496 -229c37,26 77,38 121,38 59,0 110,-20 152,-62 42,-41 63,-92 63,-151 0,-74 -31,-133 -92,-176 -37,-25 -78,-37 -123,-37 -75,0 -133,30 -174,90 -26,38 -38,79 -38,123 0,74 30,133 91,175z"
            />
            <path
              class="fil0"
              d="M570 848c-43,14 -90,21 -140,21 -91,0 -174,-25 -247,-77 -74,-52 -126,-120 -157,-206 -17,-46 -26,-95 -26,-147 0,-91 26,-174 77,-247 52,-74 121,-126 207,-157 49,-19 98,-28 148,-28 62,0 119,12 173,36l265 385 -300 420zm-261 -233c37,26 77,38 121,38 59,0 110,-20 152,-62 42,-41 63,-92 63,-151 0,-74 -31,-133 -92,-176 -37,-25 -78,-37 -123,-37 -75,0 -133,30 -174,90 -26,38 -38,79 -38,123 0,74 30,133 91,175z"
            />
            <polygon class="fil1" points="1185,889 865,889 1028,625 " />
            <polygon class="fil1" points="1191,0 1036,240 886,0 " />
          </g>
        </g>
      </svg>
      <a class="contact-phone-mm" href="tel:+79892224422">+7 989 222 44 22</a>
      <a class="contact-phone-mm" href="mailto:admin@axas.ru">admin@axas.ru</a>
      <p class="contact-phone-mm">350051 г. Краснодар ул. Монтажников 1/4 оф. 1205</p>
      <p class="contact-phone-mm">ОГРНИП: 317237500402141</p>
      <p class="contact-phone-mm">ИНН: 233710743508</p>
      <!--<a class="contact-phone-mm" href="/certificate.pdf" target="_blank">
        Запатентованный продукт
      </a>-->
    </div>

    <div class="content">
      <h1 class="content__title">{{ page.mobile.title }}</h1>
      <p class="content__description">{{ page.mobile.description }}</p>
      <input
        @click="scrollToEnd"
        type="button"
        class="content__button"
        value="Оставить заявку"
      />
    </div>
  </div>
</template>

<script setup>
  import appContent from '@/appContent'

  const page = appContent.pageList.find((page) => page.name === 'AVACore')
  const bg = page.mobile.bg
</script>

<script>
  export default {
    name: 'MainPage',
    methods: {
      scrollToEnd() {
        // window.document.scrollingElement.scrollTop = 999999
        this.$store.commit('setActivePageIndex', 6)
      },
    },
  }
</script>

<style scoped lang="scss">
  .fil1 {
    fill: #fefefe;
  }
  .fil0 {
    fill: #fefefe;
    fill-rule: nonzero;
  }

  .page.main {
    position: absolute;
    width: 100vw;
    height: 100vh;
    transition: 0.7s;
    &:not(.active) {
      opacity: 0;
      pointer-events: none;
    }

    .background {
      position: absolute;
      background-image: v-bind(bg);
      background-size: cover;
      background-position: 50%;
      height: 100vh;
      width: 100vw;
    }
    .logo {
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-left: 5vw;
      margin-top: 5vw;
      svg {
        width: calc((1vw + 1vh) * 5);
        fill: #ffffff;
        filter: drop-shadow(2px 3px 4px rgba(#414141, 0.2));
      }
      .contact-phone-mm {
        width: min-content;
        white-space: nowrap;
        font-weight: bold;
        margin-top: 3vw;
        font-size: 3.4vw;
        z-index: 1000000;
      }
      .contact-phone-mm + .contact-phone-mm {
        margin-top: 2vw;
      }
    }

    .content {
      position: relative;
      top: 35%;
      width: 100vw;
      height: fit-content;
      padding: 0 5.2vw 5.2vw;
      border-radius: 4vw;

      &__description {
        font-size: 4.5vw;
        line-height: 1.5;
        margin-bottom: 4vw;
      }
      &__title {
        line-height: 1.6;
        font-size: 6vw;
        font-weight: bold;
        font-family: Halvar;
        margin-bottom: 2vw;
      }
      &__button {
        padding: 3.5vw 6vw;
        border-radius: 2vw;
        font-size: 4.5vw;
        font-weight: bold;
        font-family: Halvar;
        letter-spacing: 0.3vw;
        color: #fff;
        cursor: pointer;
        background: linear-gradient(to right bottom, #0d5bb5, #7721a5);
      }
    }
  }
</style>
