<template>
  <swiper @swiper="(value) => (swiper = value)" class="text-slider">
    <swiper-slide class="slide" v-for="(project, index) in projectsInfo" :key="index">
      <div v-if="index > 0" @click="prevHandler" class="nav__button prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          viewBox="0 0 330 330"
          style="enable-background: new 0 0 0 0"
          xml:space="preserve"
        >
          <path
            d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
          />
        </svg>
      </div>
      <div class="project">
        <div class="project__title">
          {{ project.title }}
        </div>
        <div class="project__text">
          {{ [project.firstLine, project.secondLine, project.thirdLine].join(' ') }}
        </div>
      </div>
      <div @click="nextHandler" class="nav__button next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          viewBox="0 0 330 330"
          style="enable-background: new 0 0 0 0"
          xml:space="preserve"
        >
          <path
            d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
          />
        </svg>
      </div>
    </swiper-slide>
    <swiper-slide class="slide last-slide">
      <div @click="prevHandler" class="nav__button prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          viewBox="0 0 330 330"
          style="enable-background: new 0 0 0 0"
          xml:space="preserve"
        >
          <path
            d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
          />
        </svg>
      </div>
      <div class="project">
        <div class="project__title">Хотите заказать проект?</div>
        <div class="project__text">
          Мы создаем огромный спектр мобильных и web приложений.
          <span @click="scrollToLast" class="link form">Оставьте заявку</span>
          и мы свяжемся с вами.
        </div>
        <div class="project__text">
          <a href="https://axas.ru/portfolio/index.html" class="link more">
            Больше проектов
            <svg
              class="arrow"
              viewBox="0 0 37 16"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.7071 8.7071C37.0976 8.31658 37.0976 7.68341 36.7071 7.29289L30.3431 0.92893C29.9526 0.538405 29.3195 0.538405 28.9289 0.92893C28.5384 1.31945 28.5384 1.95262 28.9289 2.34314L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.7071ZM8.74228e-08 9L36 9L36 7L-8.74228e-08 7L8.74228e-08 9Z"
              />
            </svg>
          </a>
        </div>
      </div>
      <!--      <div class="last-slide__content">-->
      <!--        <div class="last-slide__button">Оставить заявку</div>-->
      <!--        <div class="last-slide__more">-->
      <!--          <div class="last-slide__more_text">Больше проектов</div>-->
      <!--          <div class="last-slide__more_icon">-->
      <!--            <svg-->
      <!--              class="arrow"-->
      <!--              viewBox="0 0 37 16"-->
      <!--              fill="white"-->
      <!--              xmlns="http://www.w3.org/2000/svg"-->
      <!--            >-->
      <!--              <path-->
      <!--                d="M36.7071 8.7071C37.0976 8.31658 37.0976 7.68341 36.7071 7.29289L30.3431 0.92893C29.9526 0.538405 29.3195 0.538405 28.9289 0.92893C28.5384 1.31945 28.5384 1.95262 28.9289 2.34314L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.7071ZM8.74228e-08 9L36 9L36 7L-8.74228e-08 7L8.74228e-08 9Z"-->
      <!--              />-->
      <!--            </svg>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </swiper-slide>
  </swiper>
</template>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue'

  import 'swiper/css'

  export default {
    name: 'PageText',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      projectsInfo: Array,
    },
    data() {
      return {
        swiper: null,
      }
    },
    methods: {
      nextHandler() {
        this.$emit('next')
        this.swiper.slideNext()
      },
      prevHandler() {
        this.$emit('prev')
        this.swiper.slidePrev()
      },
      scrollToLast() {
        // window.document.scrollingElement.scrollTop = 999999
        this.$store.commit('setActivePageIndex', 6)
      },
    },
  }
</script>

<style scoped lang="scss">
  .active {
    .text-slider {
      opacity: 1;
    }
  }

  .text-slider {
    position: absolute;
    left: calc(50% - 45vw);
    bottom: 12%;
    width: 90vw;
    border-radius: 3vw;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10001;
    transition: 0.7s;
    opacity: 0;

    .slide {
      display: flex;
      height: auto;
      .nav__button {
        cursor: pointer;
        width: 8vw;
        flex-shrink: 0;
        display: flex;
        background: rgba(#fff, 0.15);
        transition: 0.3s;
        justify-content: center;
        align-items: center;

        &:hover {
          background: rgba(#fff, 0.25);
        }
        svg {
          width: 80%;
          pointer-events: none;
        }
        &.next {
          svg {
            transform: rotateZ(90deg);
          }
        }
        &.prev {
          svg {
            transform: rotateZ(-90deg);
          }
        }
      }

      &.last-slide {
        .project__text {
          &:not(:first-child) {
            margin-top: 2vw;
          }

          .arrow {
            width: 7vw;
            margin-left: 2vw;
          }

          .link {
            cursor: pointer;
            font-family: Roboto;
            font-weight: 900;
            font-size: 1.15em;

            &.more {
              font-weight: 500;
              font-style: italic;
              display: flex;
              align-items: center;
            }
          }
        }
        .last-slide__content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
        }
        .last-slide__button {
          cursor: pointer;
          width: fit-content;
          font-weight: bold;
          padding: 12px 24px;
          border-radius: 2vw;
          font-size: 3.5vw;
          background: rgba(#fff, 1);
          color: black;
          box-shadow: 1px 1px 4px rgba(#414141, 0.4);
        }
        .last-slide__more {
          display: flex;
          margin-top: 2.5vw;
          margin-bottom: 6.5%;

          .last-slide__more_text {
            font-style: italic;
          }
          &_icon {
            margin-left: 2vw;
            width: 5vw;
            stroke-width: 1;
            stroke: rgb(255, 255, 255);
          }
        }
      }
    }
    .project {
      padding: 3vw;

      &__title {
        font-family: 'Halvar';
        font-weight: bold;
        margin-bottom: 0.4vw;
        font-size: 2.4vw;
        line-height: 1.5;
      }
      &__text {
        font-size: 2vw;
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 600px) {
    .text-slider {
      .project {
        &__title {
          font-size: 4vw;
        }
        &__text {
          font-size: 2.9vw;
        }
      }
    }
  }
</style>
