export default {
  namespaced: true,
  state: {
    notification: {
      title: 'Не удалось отправить заявку',
      subtitle: 'Воспользуйтесь контактами или попробуйте позже.',
      isShow: false,
    },
  },
  getters: {
    notification: (state) => state.notification,
  },
  mutations: {
    setNotification(state, newValue) {
      state.notification = {
        ...state.notification,
        ...newValue,
      }
    },
  },
  actions: {},
}
