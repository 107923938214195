<template>
  <div class="page not-alone">
    <img class="moc" :src="content.moc2" alt="" />
    <img class="moc" :src="content.moc1" alt="" />
    <img class="moc" :src="content.moc4" alt="" />
    <img class="moc" :src="content.moc5" alt="" />
    <img class="moc" :src="content.moc3" alt="" />
  </div>
</template>

<script setup>
  import appContent from '@/appContent'

  const content = appContent.pageList.find((page) => page.name === 'Payments').content
</script>

<style scoped lang="scss">
  .page.active.not-alone {
    .moc {
      transform: translateY(0);
    }
  }
  .page {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:not(.active) {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
    .moc {
      position: absolute;
      width: 15vw;
      object-fit: contain;
      border-radius: 3.3vw;

      &:nth-child(1),
      &:nth-child(2) {
        transform: translateY(-70vh);
      }
      &:nth-child(3),
      &:nth-child(4) {
        transform: translateY(70vh);
      }
      &:nth-child(5) {
        transform: translateX(70vh);
      }

      &:nth-child(1) {
        left: 14%;
        top: 8%;
        transition: 1s;
      }
      &:nth-child(2) {
        left: 40%;
        top: 1%;
        transition: 1.3s;
      }
      &:nth-child(3) {
        left: 14%;
        top: 55%;
        transition: 1.3s;
      }
      &:nth-child(4) {
        left: 40%;
        top: 45%;
        transition: 1s;
      }
      &:nth-child(5) {
        left: 67%;
        top: 35%;
        transition: 1s;
      }
    }
  }
</style>
