<template>
  <div class="slider mobile">
    <swiper
      :modules="modules"
      :slidesPerView="sliderPerView"
      class="mySwiper"
      :navigation="true"
      :centeredSlides="true"
      :autoplay="{
        delay: 3500,
        disableOnInteraction: true,
      }"
      :loop="true"
      @swiper="onSwiper"
    >
      <swiper-slide class="slide" v-for="(item, index) in content" :key="index">
        <img :src="item.photo" :alt="item.name" />
        <div class="info">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="position">
            {{ item.position }}
          </div>
          <ul class="competence-list">
            <div class="competence-list__title">Навыки:</div>
            <li
              v-for="(comp, index) in item.competenceList"
              :key="index"
              class="competence-list__item"
            >
              {{ comp }}
            </li>
          </ul>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
  import appContent from '@/appContent'

  const content = appContent.pageList.find((page) => page.name === 'Team').team
</script>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue'

  // Import Swiper styles
  import 'swiper/css'

  import { Autoplay, Controller } from 'swiper'
  export default {
    name: 'TeamSliderMobile',
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        modules: [Autoplay, Controller],
        swiper: null,
        swiperInfo: null,
        sliderPerView: 1,
      }
    },
    methods: {
      onSwiper(swiper) {
        this.swiper = swiper
        this.tryMerge()
      },
      onSwiperInfo(swiperInfo) {
        this.swiperInfo = swiperInfo
        this.tryMerge()
      },
      tryMerge() {
        if (this.swiper && this.swiperInfo) {
          this.swiperInfo.controller.control = this.swiper
          this.swiper.controller.control = this.swiperInfo
        }
      },
      onNext() {
        this.swiper.slideNext()
      },
      onPrev() {
        this.swiper.slidePrev()
      },
    },
  }
</script>

<style lang="scss">
  .slider.mobile {
    --size: 88vh;
    position: relative;
    width: 100vw;
    height: var(--size);
    display: flex;
    align-items: center;
    border-radius: 0.5vw;
    overflow: hidden;
    background: rgba(grey, 0.2);
    --font-size1: 4.2vw;
    --font-size2: 3.9vw;
    --font-size3: 3.6vw;
    --padding-header1: 2.3vw 2.3vw 1.1vw 4.3vw;
    --padding-header2: 0 2.3vw 2.3vw 4.3vw;
    --padding-competence-list: 2.3vw 2.3vw 2.3vw 4.3vw;

    &__nav {
      width: 2vw;
      height: var(--size);
      background: rgba(#fff, 0.05);
      color: #edf0f4;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s !important;
      &:hover {
        background: rgba(#fff, 0.15);
        color: #eff4fc;
      }
      &[disabled='true'] {
        color: rgba(#afafbf, 0.6);
        background: rgba(#a7adb7, 0.25);
      }

      &.next {
        margin-left: 4px;
        svg {
          transform: rotateZ(90deg);
        }
      }
      &.prev {
        margin-right: 4px;
        svg {
          transform: rotateZ(-90deg);
        }
      }
    }
    .mySwiper {
      height: var(--size);

      .swiper-slide {
        position: relative;
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100vw;
          height: 42vh !important;
          object-fit: cover;
          background: rgba($primary, 0.2);
        }
        .info {
          background: rgba(#fff, 0.95);
          height: 60vh;
          display: flex;
          flex-direction: column;
          color: #0f1e34;
          width: 100vw;
          .name {
            font-size: var(--font-size1);
            padding: var(--padding-header1);
            font-weight: 500;
            font-family: 'Roboto';
            width: 100%;
          }
          .position {
            font-size: calc(var(--font-size1) * 0.9);
            padding: var(--padding-header2);
            border-bottom: 1px solid #0f1e34;
            font-family: 'Roboto';
            width: 100%;
          }
          .competence-list {
            padding: var(--padding-competence-list);

            &__title {
              align-self: flex-start;
              margin-bottom: 2vw;
              font-size: var(--font-size2);
            }
            &__item {
              line-height: 1.5;
              list-style: circle;
              margin-left: 6vw;
              font-size: var(--font-size3);

              &:not(:last-child) {
                margin-top: 0.2vw;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 450px) {
    .slider.mobile {
      --font-size1: 5.2vw;
      --font-size2: 4.5vw;
      --font-size3: 4vw;
      --padding-header1: 3vw 3vw 1.5vw 5vw;
      --padding-header2: 0 3vw 3vw 5vw;
      --padding-competence-list: 4vw 3vw 3vw 5vw;
    }
  }
</style>
